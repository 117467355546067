<template>
  <layout-stage backgroundColor="#086769" themeName="common_areas">
    <template slot="nav-layout">
      <nav class="block-menu animate__animated animate__bounceInLeft">
        <router-link class="back-button" :to="{ name: 'Home' }" exact>
          <icon-back class="icon-menu" />
          Voltar
        </router-link>

        <ul class="block-menu_secondary">
          <li>
            <router-link :to="{ name: 'CommonAreas' }" exact>
              <icon-home />
              Residencial
            </router-link>
          </li>
        </ul>
      </nav>
    </template>
    <template slot="main-layout">
      <section class="block-content animate__animated animate__bounceInRight">
        <swiper class="swiper" ref="mySwiper" :options="swiperOptions">
          <swiper-slide v-for="(card, index) in MockPhoto" :key="index">
            <div class="block-slide">
              <img class="slider-image" alt="" :src="card.image" />
              <img
                class="slider-image"
                alt=""
                src="~@/assets/images/areas-comuns/bg-gradient.png"
              />
              <div class="slider-content">
                <h3>{{ card.title }}</h3>
              </div>
            </div>
          </swiper-slide>
          <div class="swiper-button-prev" slot="button-prev"></div>
          <div class="swiper-button-next" slot="button-next"></div>
        </swiper>
      </section>
    </template>
  </layout-stage>
</template>

<script>
import LayoutStage from "@/components/LayoutStage";
import IconBack from "@/components/icons/IconBack";
import IconHome from "@/components/icons/IconHome";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

export default {
  name: "CommonAreas",

  components: {
    LayoutStage,
    IconBack,
    IconHome,
    Swiper,
    SwiperSlide,
  },

  data() {
    return {
      MockPhoto: [
        {
          title: "Guarita",
          image: require("@/assets/images/areas-comuns/area-comum-01.png"),
        },
        {
          title: "Pergolado",
          image: require("@/assets/images/areas-comuns/area-comum-02.png"),
        },
        {
          title: "Salão de Festa",
          image: require("@/assets/images/areas-comuns/area-comum-03.png"),
        },
        {
          title: "Piscina",
          image: require("@/assets/images/areas-comuns/area-comum-04.png"),
        },
        {
          title: "Entrada residencial",
          image: require("@/assets/images/areas-comuns/area-comum-05.png"),
        },
        {
          title: "Quadra gramada",
          image: require("@/assets/images/areas-comuns/area-comum-06.png"),
        },
        {
          title: "Ginástica",
          image: require("@/assets/images/areas-comuns/area-comum-07.png"),
        },
        {
          title: "Quadra Infatil",
          image: require("@/assets/images/areas-comuns/area-comum-08.png"),
        },
        {
          title: "Churrasqueira",
          image: require("@/assets/images/areas-comuns/area-comum-09.png"),
        },
        {
          title: "Playground",
          image: require("@/assets/images/areas-comuns/area-comum-10.png"),
        },
        {
          title: "Bicicletário",
          image: require("@/assets/images/areas-comuns/area-comum-11.png"),
        },
        {
          title: "Terraço Festas",
          image: require("@/assets/images/areas-comuns/area-comum-12.png"),
        },
        {
          title: "Playground II",
          image: require("@/assets/images/areas-comuns/area-comum-13.png"),
        },
        {
          title: "Redário",
          image: require("@/assets/images/areas-comuns/area-comum-16.png"),
        },
        {
          title: "Vista aérea do lazer",
          image: require("@/assets/images/areas-comuns/area-comum-17.png"),
        },
      ],
      swiperOptions: {
        slidesPerView: 1,
        spaceBetween: 10,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
          clickable: true,
        },
      },
    };
  },

  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.swiper-container {
  padding-top: 20px;
  padding-bottom: 50px;
  padding-left: 44px;
}

::v-deep.swiper-button-prev,
.swiper-button-next {
  width: 70px !important;
  top: 48%;
}

.swiper-button-prev:after,
.swiper-container-rtl .swiper-button-next:after {
  color: var(--pineGreen);
  font-size: 22px;
  background: var(--amulet);
  height: 70px;
  width: 70px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 926px) and (orientation: landscape) {
    width: 50px;
    height: 50px;
  }
}

.swiper-button-next:after,
.swiper-container-rtl .swiper-button-next:after {
  color: var(--pineGreen);
  font-size: 22px;
  background: var(--amulet);
  height: 70px;
  width: 70px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 926px) and (orientation: landscape) {
    width: 50px;
    height: 50px;
  }
}

.block-menu {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.block-content {
  margin-top: 10px;
  max-width: 1311px;
  width: 100%;

  .block-slide {
    width: 1222px;
    height: 755px;
    border-radius: 30px;
    position: relative;
    background-color: var(--sauvignon);
    background: var(--amulet);
    border-radius: 10px 0 70px 15px;

    .slider-image {
      width: 1207px;
      height: 755px;
      border-radius: 10px 0 77px 15px;
      position: absolute;
      object-fit: cover;
      bottom: 0px;
      left: 0px;
    }

    .slider-content {
      position: absolute;
      bottom: 20px;
      left: 40px;
      width: 100%;

      h3 {
        color: var(--white);
        font-size: 30px;
        line-height: 30px;
        font-weight: var(--weight-regular);
        margin-bottom: 5px;
      }
    }
  }

  //Responsive mode

  @media (max-width: 1680px) {
  }

  @media (max-width: 1440px) {
    max-width: 900px;

    .block-slide {
      width: 812px;
      height: 570px;

      .slider-image {
        width: 800px;
        height: 570px;
      }
    }
  }

  @media (max-width: 1366px) {
  }

  @media (max-width: 1200px) {
    margin-top: 50px;
    margin-left: -30px;
    max-width: 820px;

    .block-slide {
      width: 720px;
      height: 570px;

      .slider-image {
        width: 800px;
        height: 570px;
      }

      .slider-content {
        left: 175px;
      }
    }
  }

  @media (max-width: 1024px) {
    margin-top: 50px;
    margin-left: -40px;
    max-width: 740px;

    .block-slide {
      width: 640px;
      height: 500px;

      .slider-image {
        width: 800px;
        height: 500px;
      }

      .slider-content {
        left: 175px;
      }
    }
  }

  @media only screen and (max-width: 926px) and (orientation: landscape) {
    margin-top: 15px;
    margin-left: -40px;
    max-width: 560px;
    position: fixed;
    right: 0;
    bottom: 10px;

    .block-slide {
      width: 460px;
      height: 230px;

      .slider-image {
        width: 460px;
        height: 230px;
      }

      .slider-content {
        left: 65px;
        h3 {
          font-size: 18px;
        }
      }
    }
  }

  @media only screen and (max-width: 780px) and (orientation: landscape) {
    bottom: -35px;
    margin-left: -40px;
    max-width: 480px;
    max-height: 310px;

    .block-slide {
      width: 400px;
      height: 240px;

      .slider-image {
        width: 400px;
        height: 240px;
      }

      .slider-content {
        bottom: 10px;
        left: 25px;
        h3 {
          font-size: 14px;
          line-height: 60px;
        }
      }
    }
  }

  @media (max-width: 568px) {
    margin-top: 15px;
    margin-left: -40px;
    max-width: 400px;

    .block-slide {
      width: 300px;
      height: 160px;
      border-radius: 20px;

      .slider-image {
        width: 300px;
        height: 160px;
        border-radius: 20px;
      }

      .slider-content {
        left: 35px;
        h3 {
          font-size: 14px;
          line-height: 60px;
        }
      }
    }
  }
}

.back-button {
  color: var(--amulet);
  font-weight: var(--weight-regular);

  ::v-deep.icon-menu {
    stroke: var(--amulet);
  }
}

.block-menu_secondary {
  a {
    color: var(--amulet);

    ::v-deep.icon-menu {
      stroke: var(--amulet);
    }

    &:hover,
    &:active,
    &:focus,
    &.router-link-active {
      color: var(--pineGreen);
      background: var(--amulet);

      ::v-deep.icon-menu {
        stroke: var(--pineGreen);
      }
    }
  }
}
</style>
