<template>
  <div id="app">
    <router-view />
    <div v-if="suggestLandscapeModal" class="modal-suggest-landscape">
      <p>
        Por favor, para uma melhor experiência use o celular no modo paisagem.
      </p>
      <img src="~@/assets/images/icon-landscape.svg" alt="" />
      <button @click="setSuggestLandscapeModal()" class="button-landscape">
        Entendi
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",

  data() {
    return {
      suggestLandscapeModal: true,
    };
  },

  methods: {
    setSuggestLandscapeModal() {
      this.$router.go();
      this.suggestLandscapeModal = false;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/styleguide";
@import "@/assets/scss/responsive";

.modal-suggest-landscape {
  display: none;
}

@media only screen and (max-width: 926px) and (orientation: portrait) {
  .modal-suggest-landscape {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: var(--tumbleweed);
    box-sizing: border-box;
    padding: 30px;
    display: flex;
    align-items: center;
    flex-direction: column;
    z-index: 9999;
    p {
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      color: var(--blackRose);
      max-width: 260px;
      margin: 50px auto;
      width: 100%;
      font-weight: var(--weight-bold);
    }

    img {
      max-width: 200px;
      height: auto;
    }

    .button-landscape {
      padding: 6px 12px;
      border-radius: 50px;
      width: 206px;
      height: 40px;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      font-weight: var(--weight-regular);
      font-size: 18px;
      line-height: 18px;
      margin: 0;
      background: var(--blackRose);
      color: var(--tumbleweed);
      transition: all 200ms ease-in-out;
      cursor: pointer;
      border: none;

      &:hover,
      &:active,
      &:focus,
      &.router-link-active {
        transition: all 200ms ease-in-out;
      }
    }
  }
}

* {
  box-sizing: border-box;
  outline: none;
}

html {
  overflow: hidden !important;
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0px;
  display: flex;
  font-family: var(--font-primary) !important;
}

#app {
  width: 100%;
}

@media only screen and (max-width: 780px) and (orientation: landscape) {
  html,
  body,
  #app {
    overflow: hidden !important;
  }
}

//Back Button
.back-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 58px 0 70px;
  font-size: 28px;
  line-height: 28px;
  font-weight: var(--weight-regular);

  .icon-menu {
    margin-right: 14px;
    width: 32px;
    height: auto;
  }

  @media only screen and (max-width: 926px) and (orientation: landscape) {
    margin: 20px 0 20px;
    font-size: 22px;
    line-height: 22px;

    .icon-menu {
      margin-right: 10px;
      width: 22px;
    }
  }

  @media only screen and (max-width: 780px) and (orientation: landscape) {
    margin: 12px 0 12px;
    font-size: 16px;
    line-height: 16px;

    .icon-menu {
      margin-right: 6px;
      width: 18px;
    }
  }
}

//Menu Secondary
.block-menu_secondary {
  margin-left: 36px;

  a {
    padding: 20px 28px;
    border-radius: 20px;
    width: 416px;
    height: 101px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: var(--weight-semibold);
    font-size: 30px;
    line-height: 30px;
    margin: 10px 0 2px;
    transition: all 200ms ease-in-out;
    svg {
      margin-right: 12px;
      width: 42px;
    }

    &:hover,
    &:active,
    &:focus,
    &.router-link-active {
      transition: all 200ms ease-in-out;
    }
  }

  @media (max-width: 1680px) {
  }

  @media (max-width: 1440px) {
    a {
      width: 300px;
      height: 70px;
      font-weight: var(--weight-regular);
      font-size: 24px;
    }
  }

  @media (max-width: 1366px) {
  }

  @media (max-width: 1200px) {
    margin-left: 0;
    margin-right: 30px;
    a {
      width: 250px;
      height: 60px;
      font-size: 20px;
    }
  }

  @media (max-width: 1024px) {
  }

  @media only screen and (max-width: 926px) and (orientation: landscape) {
    margin-left: 0;
    margin-right: 30px;
    a {
      width: 150px;
      height: 40px;
      font-size: 14px;
      line-height: 14px;
      padding: 8px 12px;

      svg {
        width: 25px;
        margin-right: 6px;
      }
    }
  }

  @media only screen and (max-width: 780px) and (orientation: landscape) {
    a {
      width: 130px;
      height: 25px;
      font-size: 10px;
      line-height: 10px;
      padding: 6px 12px;
      margin: 6px 0 2px;

      svg {
        display: none;
      }
    }
  }

  @media (max-width: 568px) {
    a {
      width: 130px;
      height: 25px;
      font-size: 10px;
      line-height: 10px;
      padding: 6px 12px;
      margin: 6px 0 2px;

      svg {
        display: none;
      }
    }
  }
}
</style>
