var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{staticClass:"compare-modal",attrs:{"destroy-on-hide":true,"active":true}},[_c('div',{staticClass:"compare-modal_content"},[_c('img',{staticClass:"button-close",attrs:{"alt":"","src":require("@/assets/images/icon-close-modal.svg")},on:{"click":function($event){return _vm.$emit('close')}}}),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('div',{staticClass:"box-compare box-compare-selected"},[_c('h2',[_vm._v(_vm._s(_vm.activeUnit.name))]),_c('h3',[_vm._v(_vm._s(_vm.activeUnit.meters))]),_c('div',{staticClass:"image-unit_box"},[_c('img',{staticClass:"image-unit",attrs:{"src":_vm.activeUnit.imageUnit,"alt":""}})]),_c('img',{staticClass:"image-mapper",attrs:{"src":_vm.activeUnit.imageMapper,"alt":""}})])]),_c('div',{staticClass:"column"},[_c('div',{staticClass:"box-compare box-compare-units"},[_c('swiper',{ref:"compareSwiper",staticClass:"swiper",attrs:{"options":_vm.swiperOption}},_vm._l((_vm.initialCarouselUnits),function(unit,index){return _c('swiper-slide',{key:index},[_c('h2',[_vm._v(_vm._s(unit.name))]),_c('h3',[_vm._v(_vm._s(unit.meters))]),_c('div',{staticClass:"image-unit_box"},[_c('img',{class:[
                    'image-unit',
                    unit.imageUnitDouble &&
                    _vm.openDoubleImage &&
                    index === _vm.setIndex
                      ? 'hide-image'
                      : '' ],attrs:{"src":unit.imageUnit,"alt":""}}),(_vm.openDoubleImage && index === _vm.setIndex)?_c('img',{class:_vm.image - unit,attrs:{"src":unit.imageUnitDouble,"alt":""}}):_vm._e(),(unit.imageUnitDouble && !_vm.openDoubleImage)?_c('a',{staticClass:"button-doubleUnit",on:{"click":function($event){return _vm.openImageUnitDouble(index)}}},[_vm._v("Ver 2º pavimento")]):_vm._e(),(unit.imageUnitDouble && _vm.openDoubleImage)?_c('a',{staticClass:"button-doubleUnit",on:{"click":function($event){return _vm.openImageUnitDouble(index)}}},[_vm._v("Ver 1º pavimento")]):_vm._e()]),_c('img',{staticClass:"image-mapper",attrs:{"src":unit.imageMapper,"alt":""}})])}),1),_c('div',{staticClass:"static-pagination"},_vm._l((_vm.initialCarouselUnits),function(unit,index){return _c('button',{key:index,staticClass:"pagination-button",class:{
                active: index === _vm.setIndex,
              },on:{"click":function($event){return _vm.goSlider(index)}}},[_c('span')])}),0)],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }