<template>
  <nav class="main-menu animate__animated animate__bounceInLeft">
    <router-link :to="{ name: 'AboutComplex' }" exact>
      <img
        class="
          icon-menu
          animate__animated animate__pulse animate__delay-1s animate__infinite
        "
        alt="Icon About"
        src="~@/assets/images/icon-about.svg"
      />
      Sobre o complexo
    </router-link>
    <router-link :to="{ name: 'About' }" exact>
      <img
        class="
          icon-menu
          animate__animated animate__pulse animate__delay-1s animate__infinite
        "
        alt="Icon About"
        src="~@/assets/images/icon-reserva.svg"
      />
      Localização
    </router-link>
    <router-link :to="{ name: 'Project' }" exact>
      <img
        class="
          icon-menu
          animate__animated animate__pulse animate__delay-1s animate__infinite
        "
        alt="Icon Project"
        src="~@/assets/images/icon-project.svg"
      />
      O Projeto
    </router-link>
    <router-link :to="{ name: 'CommonAreas' }" exact>
      <img
        class="
          icon-menu
          animate__animated animate__pulse animate__delay-1s animate__infinite
        "
        alt="Icon Common Area"
        src="~@/assets/images/icon-common-areas.svg"
      />
      Áreas comuns
    </router-link>
    <router-link :to="{ name: 'Units' }" exact>
      <img
        class="
          icon-menu
          animate__animated animate__pulse animate__delay-1s animate__infinite
        "
        alt="Icon Unit"
        src="~@/assets/images/icon-units.svg"
      />
      Unidades
    </router-link>
  </nav>
</template>

<script>
export default {
  name: "MainMenu",
};
</script>

<style lang="scss" scoped>
.main-menu {
  display: flex;
  flex-direction: column;
  padding: 200px 42px 42px 42px;
  margin: -132px 0 0 0;
  background: rgba(11, 96, 98, 0.6);
  backdrop-filter: blur(15px);
  position: relative;
  z-index: 8;
  border-radius: 0 0 110px 0;

  a {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 16px;
    color: var(--chelseaCucumber);
    font-size: 40px;
    line-height: 76px;
    font-weight: var(--weight-regular);

    .icon-menu {
      width: 56px;
      margin-right: 32px;
      height: auto;
      display: inline-block;
    }

    &:last-child {
      margin-bottom: 0px;
    }
  }

  //Responsive mode
  @media (max-width: 1680px) {
    margin: -132px 0 0 0;

    a {
      font-size: 36px;
      line-height: 66px;

      .icon-menu {
        width: 48px;
        margin-right: 20px;
        height: auto;
        display: inline-block;
      }
    }
  }

  @media (max-width: 1440px) {
  }

  @media (max-width: 1366px) {
  }

  @media (max-width: 1200px) {
    margin: 170px 0 0 0px;
    a {
      font-size: 35px;
      line-height: 61px;

      .icon-menu {
        width: 43px;
        margin-right: 24px;
      }
    }
  }

  @media (max-width: 1024px) {
  }

  @media only screen and (max-width: 926px) and (orientation: landscape) {
    margin: -85px 0 0 0px;
    padding: 80px 42px 42px 42px;
    a {
      font-size: 14px;
      line-height: 21px;
      margin-bottom: 10px;

      .icon-arrow {
        width: 20px;
        height: 20px;
      }

      .icon-menu {
        width: 26px;
        margin-right: 16px;
      }
    }
  }

  @media only screen and (max-width: 780px) and (orientation: landscape) {
  }
}
</style>
