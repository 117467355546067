<template>
  <b-modal :destroy-on-hide="true" :active="true" class="compare-modal">
    <div class="compare-modal_content">
      <img
        class="button-close"
        alt=""
        src="~@/assets/images/icon-close-modal.svg"
        @click="$emit('close')"
      />
      <div class="compare-modal_photo">
        <img alt="" :src="photo" class="photo" />
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "ModalPhoto",

  props: {
    photo: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.button-close {
  position: fixed;
  top: 30px;
  right: 30px;
  cursor: pointer;
}

.compare-modal {
  ::v-deep.modal-close {
    display: none;
  }

  ::v-deep.modal-content {
    overflow: initial;
    max-width: 1200px !important;
  }

  .compare-modal_photo {
    display: flex;
    align-items: center;
    justify-content: center;
    .photo {
      max-height: 660px;
    }
  }

  //Responsive mode
  @media only screen and (max-width: 926px) and (orientation: landscape) {
    ::v-deep.modal-content {
      max-width: 600px !important;
    }

    .compare-modal_photo {
      max-width: 600px;
      margin-top: -40px;
      .photo {
        max-width: 600px;
        max-height: 360px;
        padding: 40px;
      }
    }
  }

  @media only screen and (max-width: 780px) and (orientation: landscape) {
    .compare-modal_photo {
      .photo {
        max-width: 600px;
        max-height: 320px;
      }
    }
  }

  @media (max-width: 568px) {
    ::v-deep.modal-content {
      max-width: 500px !important;
    }

    .compare-modal_photo {
      max-width: 500px;
      .photo {
        max-width: 500px;
        max-height: 240px;
      }
    }
  }
}
</style>
