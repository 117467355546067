<template>
  <layout-stage backgroundColor="#889B7B" themeName="project">
    <template slot="nav-layout">
      <nav class="block-menu animate__animated animate__bounceInLeft">
        <router-link class="back-button" :to="{ name: 'Home' }" exact>
          <icon-back class="icon-menu" />
          Voltar
        </router-link>

        <ul class="block-menu_secondary">
          <li>
            <router-link :to="{ name: 'Project' }" exact>
              <icon-project />
              Projetistas
            </router-link>
          </li>
          <!-- <li>
            <router-link :to="{ name: 'ProjectSectorization' }" exact>
              <icon-sectorization />
              Setorização
            </router-link>
          </li> -->
          <li>
            <router-link :to="{ name: 'ProjectImplantation' }" exact>
              <icon-implantation />
              Implantação
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'ProjectDatasheet' }" exact>
              <icon-datasheet />
              Ficha técnica
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'ProjectDifferentials' }" exact>
              <icon-differentials />
              Diferenciais
            </router-link>
          </li>
        </ul>
      </nav>
    </template>
    <template slot="main-layout">
      <section class="block-content animate__animated animate__bounceInRight">
        <div class="implantation">
          <div class="implantation_box">
            <div class="implantation_infos">
              <ul>
                <li v-for="(item, index) in mockImplantation" :key="index">
                  <span
                    v-if="item.imageModal"
                    class="clickable"
                    @click="openPhoto(item.imageModal)"
                  >
                    <span class="number">
                      {{ index + 1 >= 10 ? index + 1 : `0${index + 1}` }}
                    </span>
                    {{ item.name }}
                  </span>

                  <span v-else>
                    <span class="number">
                      {{ index + 1 >= 10 ? index + 1 : `0${index + 1}` }}
                    </span>
                    {{ item.name }}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="image-map_box">
          <img
            class="icon-eye-mobile"
            src="~@/assets/images/icon-eye.svg"
            @click="openModalPhotosMobile(mockImplantation)"
          />
          <div class="image-map_points">
            <div
              v-for="(item, index) in mockImplantation"
              :key="index"
              :class="['pin pin_' + index]"
            >
              <img
                v-if="item.imageModal"
                class="
                  clickable
                  animate__animated
                  animate__headShake
                  animate__delay-2s
                  animate__infinite
                "
                @click="openPhoto(item.imageModal)"
                :src="item.imageMap"
                alt=""
              />
              <img v-else :src="item.imageMap" alt="" />
            </div>
          </div>
          <img
            class="image-implantation"
            src="~@/assets/images/image-implantation-map.png"
            alt=""
          />
        </div>
      </section>

      <modal-photo
        :photo="openPhotoSelected"
        v-if="showModalPhotos"
        @close="showModalPhotos = false"
      />

      <modal-multiple-photos
        :photos="openPhotosMobileSelected"
        v-if="showModalPhotosMobile"
        @close="showModalPhotosMobile = false"
      />
    </template>
  </layout-stage>
</template>

<script>
import LayoutStage from "@/components/LayoutStage";
import IconBack from "@/components/icons/IconBack";
import IconProject from "@/components/icons/IconProject";
// import IconSectorization from "@/components/icons/IconSectorization";
import IconImplantation from "@/components/icons/IconImplantation";
import IconDatasheet from "@/components/icons/IconDatasheet";
import IconDifferentials from "@/components/icons/IconDifferentials";
import ModalPhoto from "@/components/ModalPhoto";
import ModalMultiplePhotos from "@/components/ModalMultiplePhotos";

export default {
  name: "ProjectImplantation",

  components: {
    LayoutStage,
    IconBack,
    IconProject,
    // IconSectorization,
    IconImplantation,
    IconDatasheet,
    IconDifferentials,
    ModalPhoto,
    ModalMultiplePhotos,
  },

  data() {
    return {
      showModalPhotos: false,
      openPhotoSelected: "",
      openPhotosMobileSelected: [],
      showModalPhotosMobile: false,
      mockImplantation: [
        {
          image: require("@/assets/images/icon-implantation-01.svg"),
          imageMap: require("@/assets/images/icon-map-implantation-01.svg"),
          name: "Bicicletário",
          imageModal: require("@/assets/images/areas-comuns/area-comum-11.png"),
        },
        {
          image: require("@/assets/images/icon-implantation-02.svg"),
          imageMap: require("@/assets/images/icon-map-implantation-02.svg"),
          name: "Central de Gás",
        },
        {
          image: require("@/assets/images/icon-implantation-03.svg"),
          imageMap: require("@/assets/images/icon-map-implantation-03.svg"),
          name: "Playground 2",
          imageModal: require("@/assets/images/areas-comuns/area-comum-13.png"),
        },
        {
          image: require("@/assets/images/icon-implantation-04.svg"),
          imageMap: require("@/assets/images/icon-map-implantation-04.svg"),
          name: "Ginástica descoberta",
          imageModal: require("@/assets/images/areas-comuns/area-comum-07.png"),
        },
        {
          image: require("@/assets/images/icon-implantation-05.svg"),
          imageMap: require("@/assets/images/icon-map-implantation-05.svg"),
          name: "Quadra Gramada",
          imageModal: require("@/assets/images/areas-comuns/area-comum-06.png"),
        },
        {
          image: require("@/assets/images/icon-implantation-06.svg"),
          imageMap: require("@/assets/images/icon-map-implantation-06.svg"),
          name: "Quadra Infantil",
          imageModal: require("@/assets/images/areas-comuns/area-comum-08.png"),
        },
        {
          image: require("@/assets/images/icon-implantation-07.svg"),
          imageMap: require("@/assets/images/icon-map-implantation-07.svg"),
          name: "Churrasqueira",
          imageModal: require("@/assets/images/areas-comuns/area-comum-09.png"),
        },
        {
          image: require("@/assets/images/icon-implantation-08.svg"),
          imageMap: require("@/assets/images/icon-map-implantation-08.svg"),
          name: "Redário",
          imageModal: require("@/assets/images/areas-comuns/area-comum-16.png"),
        },
        {
          image: require("@/assets/images/icon-implantation-09.svg"),
          imageMap: require("@/assets/images/icon-map-implantation-09.svg"),
          name: "Pergolado",
          imageModal: require("@/assets/images/areas-comuns/area-comum-02.png"),
        },
        {
          image: require("@/assets/images/icon-implantation-10.svg"),
          imageMap: require("@/assets/images/icon-map-implantation-10.svg"),
          name: "Salão de festas",
          imageModal: require("@/assets/images/areas-comuns/area-comum-03.png"),
        },
        {
          image: require("@/assets/images/icon-implantation-11.svg"),
          imageMap: require("@/assets/images/icon-map-implantation-11.svg"),
          name: "Piscina Adulta",
          imageModal: require("@/assets/images/areas-comuns/area-comum-04.png"),
        },
        {
          image: require("@/assets/images/icon-implantation-13.svg"),
          imageMap: require("@/assets/images/icon-map-implantation-12.svg"),
          name: "Piscina Infantil",
        },
        {
          image: require("@/assets/images/icon-implantation-13.svg"),
          imageMap: require("@/assets/images/icon-map-implantation-13.svg"),
          name: "Playground 1",
          imageModal: require("@/assets/images/areas-comuns/area-comum-10.png"),
        },
        {
          image: require("@/assets/images/icon-implantation-14.svg"),
          imageMap: require("@/assets/images/icon-map-implantation-14.svg"),
          name: "Guarita",
          imageModal: require("@/assets/images/areas-comuns/area-comum-01.png"),
        },
      ],
    };
  },

  methods: {
    openPhoto(photoUrl) {
      this.openPhotoSelected = photoUrl;
      this.showModalPhotos = true;
    },
    openModalPhotosMobile(photos) {
      this.openPhotosMobileSelected = photos.map(function (item) {
        if (item.imageModal) {
          return item.imageModal;
        }
      });
      this.showModalPhotosMobile = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.block-menu {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.block-content {
  margin-top: 10px;
  max-width: 1300px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  background: var(--sauvignon);
  border-radius: 30px 0 70px 15px;
  margin-right: 10px;

  .image-map_box {
    position: relative;

    .icon-eye-mobile {
      display: none;
    }

    .image-map_points {
      img {
        width: 40px;

        &.clickable {
          cursor: pointer;
        }
      }

      .pin {
        position: absolute;
      }

      .pin_0 {
        top: 550px;
        right: 520px;
      }

      .pin_1 {
        top: 500px;
        right: 520px;
      }

      .pin_2 {
        top: 410px;
        right: 70px;
      }

      .pin_3 {
        top: 350px;
        right: 70px;
      }

      .pin_4 {
        top: 350px;
        right: 10px;
      }

      .pin_5 {
        top: 410px;
        right: 10px;
      }

      .pin_6 {
        top: 470px;
        right: 10px;
      }

      .pin_7 {
        top: 500px;
        right: 50px;
      }

      .pin_8 {
        top: 460px;
        right: 80px;
      }

      .pin_9 {
        top: 615px;
        right: 470px;
      }

      .pin_10 {
        top: 625px;
        right: 350px;
      }

      .pin_11 {
        top: 650px;
        right: 400px;
      }

      .pin_12 {
        top: 675px;
        right: 450px;
      }

      .pin_13 {
        top: 710px;
        right: 520px;
      }
    }
  }

  .image-implantation {
    object-fit: cover;
    max-width: 800px;
    height: 600px;
    border-radius: 30px 0 0 0;
    margin: 160px 20px 0 0;
  }

  .implantation {
    margin-top: 0px;
    border-radius: 30px 0 70px 15px;
    background: var(--pineGreen);
    height: 100%;

    .implantation_box {
      padding: 57px;
      height: 797px;
      width: 488px;
      border-radius: 30px;
      .implantation_infos {
        overflow-y: auto;
        height: 650px;

        ul {
          li {
            font-size: 24px;
            line-height: 30px;
            color: var(--sauvignon);
            margin-bottom: 32px;
            display: flex;
            align-items: center;

            span {
              display: flex;
              align-items: center;
              width: 100%;
              cursor: default;

              &.number {
                color: var(--pineGreen);
                width: 73px;
                height: 47px;
                background: url("~@/assets/images/bg-implantation-numbers.svg")
                  center center no-repeat;
              }

              &.clickable {
                cursor: pointer;
              }
            }

            img {
              width: 47px;
              margin-right: 21px;
            }
          }
        }

        &::-webkit-scrollbar {
          width: 5px;
        }

        &::-webkit-scrollbar-track {
          border-radius: 100px;
          background: rgba(0, 0, 0, 0.1);
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 100px;
          background: var(--sauvignon);
        }
      }
    }
  }

  //Responsive mode

  @media (max-width: 1680px) {
  }

  @media (max-width: 1440px) {
    max-width: 900px;

    .image-map_box {
      position: relative;

      .image-map_points {
        img {
          width: 26px;
        }

        .pin_0 {
          top: 350px;
          right: 410px;
        }

        .pin_1 {
          top: 320px;
          right: 405px;
        }

        .pin_2 {
          top: 250px;
          right: 60px;
        }

        .pin_3 {
          top: 210px;
          right: 60px;
        }

        .pin_4 {
          top: 210px;
          right: 10px;
        }

        .pin_5 {
          top: 250px;
          right: 10px;
        }

        .pin_6 {
          top: 285px;
          right: 10px;
        }

        .pin_7 {
          top: 310px;
          right: 40px;
        }

        .pin_8 {
          top: 290px;
          right: 65px;
        }

        .pin_9 {
          top: 410px;
          right: 380px;
        }

        .pin_10 {
          top: 420px;
          right: 280px;
        }

        .pin_11 {
          top: 440px;
          right: 315px;
        }

        .pin_12 {
          top: 465px;
          right: 360px;
        }

        .pin_13 {
          top: 490px;
          right: 410px;
        }
      }
    }

    .image-implantation {
      max-width: 520px;
      height: 470px;
      margin: 50px 20px 0 0;
    }

    .implantation {
      .implantation_box {
        padding: 38px;
        height: 560px;
        width: 350px;

        .implantation_infos {
          height: 480px;

          ul {
            li {
              font-size: 16px;
              line-height: 22px;
              margin-bottom: 20px;

              img {
                width: 32px;
                margin-right: 12px;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 1366px) {
  }

  @media (max-width: 1200px) {
    .image-map_box {
      .icon-eye-mobile {
        display: initial;
        cursor: pointer;
        position: absolute;
        left: 190px;
        height: 35px;
        width: 35px;
        top: 240px;
      }
      .image-map_points {
        display: none;
      }
    }
  }

  @media (max-width: 1024px) {
  }

  @media only screen and (max-width: 926px) and (orientation: landscape) {
    max-width: 530px;

    .image-map_box {
      .icon-eye-mobile {
        left: 80px;
        top: 140px;
      }
    }

    .image-implantation {
      max-width: 240px;
      height: 180px;
      margin: 70px 20px 0 0;
    }

    .implantation {
      margin-top: 0px;

      .implantation_box {
        padding: 24px;
        height: 270px;
        width: 270px;
        margin-right: 20px;

        .implantation_infos {
          height: 220px;

          ul {
            li {
              span.number {
                width: 33px;
                height: 27px;
              }
            }
          }

          p {
            font-size: 14px;
            line-height: 16px;
            margin-bottom: 12px;

            .icon-implantation {
              margin-right: 10px;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 780px) and (orientation: landscape) {
    max-width: 440px;

    .image-map_box {
      .icon-eye-mobile {
        left: 80px;
        top: 90px;
      }
    }

    .image-implantation {
      max-width: 220px;
      height: 190px;
      margin: 30px 20px 0 0;
    }

    .implantation {
      .implantation_box {
        padding: 24px;
        height: 240px;
        width: 200px;
        margin-right: 20px;

        .implantation_infos {
          height: 190px;

          p {
            font-size: 12px;
            line-height: 14px;
            margin-bottom: 8px;
          }
        }
      }
    }
  }

  @media (max-width: 568px) {
    max-width: 440px;

    .image-map_box {
      .icon-eye-mobile {
        left: 60px;
        top: 90px;
      }
    }

    .image-implantation {
      max-width: 220px;
      height: 120px;
      margin: 50px 20px 0 0;
    }

    .implantation {
      .implantation_box {
        padding: 24px;
        height: 185px;
        width: 200px;
        margin-right: 20px;

        .implantation_infos {
          height: 135px;

          p {
            font-size: 12px;
            line-height: 14px;
            margin-bottom: 8px;
          }
        }
      }
    }
  }
}

.back-button {
  color: var(--pineGreen);
  font-weight: var(--weight-regular);

  ::v-deep.icon-menu {
    stroke: var(--pineGreen);
  }
}

.block-menu_secondary {
  a {
    color: var(--pineGreen);

    ::v-deep.icon-menu {
      stroke: var(--pineGreen);
    }

    &:hover,
    &:active,
    &:focus,
    &.router-link-active {
      color: var(--amulet);
      background: var(--pineGreen);

      ::v-deep.icon-menu {
        stroke: var(--amulet);
      }
    }
  }
}
</style>
