import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import About from "../views/About.vue";
import AboutComplex from "../views/AboutComplex.vue";
import About360 from "../views/About360.vue";
import AboutPhotos from "../views/AboutPhotos.vue";
import Project from "../views/Project.vue";
import ProjectSectorization from "../views/ProjectSectorization.vue";
import ProjectImplantation from "../views/ProjectImplantation.vue";
import ProjectDatasheet from "../views/ProjectDatasheet.vue";
import ProjectDifferentials from "../views/ProjectDifferentials.vue";
import CommonAreas from "../views/CommonAreas.vue";
// import CommonAreasNeighborhood from "../views/CommonAreasNeighborhood.vue";
import Units from "../views/Units.vue";
// import Units360 from "../views/Units360.vue";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "Home",
      component: Home,
    },
    {
      path: "/sobre-o-complexo",
      name: "AboutComplex",
      component: AboutComplex,
    },
    {
      path: "/sobre/conveniencias",
      name: "About",
      component: About,
    },
    {
      path: "/sobre/fotos-interativas",
      name: "AboutPhotos",
      component: AboutPhotos,
    },
    {
      path: "/sobre/vista-360",
      name: "About360",
      component: About360,
    },
    {
      path: "/projeto",
      name: "Project",
      component: Project,
    },
    {
      path: "/projeto/setorizacao",
      name: "ProjectSectorization",
      component: ProjectSectorization,
    },
    {
      path: "/projeto/Implantacao",
      name: "ProjectImplantation",
      component: ProjectImplantation,
    },
    {
      path: "/projeto/ficha-tecnica",
      name: "ProjectDatasheet",
      component: ProjectDatasheet,
    },
    {
      path: "/projeto/diferenciais",
      name: "ProjectDifferentials",
      component: ProjectDifferentials,
    },
    {
      path: "/areas-comuns/residencial",
      name: "CommonAreas",
      component: CommonAreas,
    },
    // {
    //   path: "/areas-comuns/vizinhanca",
    //   name: "CommonAreasNeighborhood",
    //   component: CommonAreasNeighborhood,
    // },
    {
      path: "/unidades",
      name: "Units",
      component: Units,
    },
    // {
    //   path: "/unidades/vista-360",
    //   name: "Units360",
    //   component: Units360,
    // },
  ],

  scrollBehavior() {
    document.getElementById("app").scrollIntoView();
  },

  linkExactActiveClass: "active",
});

export default router;
