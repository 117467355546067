<template>
  <b-modal :destroy-on-hide="true" :active="true" class="photos-modal">
    <div class="compare-modal_content">
      <img
        class="button-close"
        alt=""
        src="~@/assets/images/icon-close-modal.svg"
        @click="$emit('close')"
      />
      <div class="compare-modal_photo">
        <swiper class="swiper" ref="photosSwiper" :options="swiperOptions">
          <swiper-slide
            class="slide"
            v-for="(photo, index) in photos"
            :key="index"
            v-show="photo !== undefined"
          >
            <div class="box-photo" v-if="photo !== undefined">
              <img alt="" :src="photo" class="photo" />
            </div>
          </swiper-slide>

          <div
            class="swiper-pagination swiper-pagination-bullets"
            slot="pagination"
          ></div>
        </swiper>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

export default {
  name: "ModalMultiplePhoto",

  components: {
    Swiper,
    SwiperSlide,
  },

  props: {
    photos: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      swiperOptions: {
        effect: "fade",
        speed: 1000,
        loop: false,
        slidesPerView: 1,
        simulateTouch: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
          clickable: true,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.button-close {
  position: fixed;
  top: 30px;
  right: 30px;
  cursor: pointer;
  z-index: 99;
}

.swiper-pagination {
  position: absolute;
  top: 50px;
  right: -15px;
  left: initial;
  display: flex;
  flex-direction: column;
}

.swiper-button-prev,
.swiper-button-next {
  display: none !important;
}

::v-deep.swiper-pagination {
  .swiper-pagination-bullet {
    background: var(--sauvignon);
    opacity: 1;
    margin: 3px 0 !important;
    width: 18px;
    height: 18px;
  }
  .swiper-pagination-bullet-active {
    background: var(--straw);
  }

  @media (max-width: 1440px) {
    .swiper-pagination-bullet {
      width: 14px;
      height: 14px;
    }
  }
}

.photos-modal {
  ::v-deep.modal-close {
    display: none;
  }

  ::v-deep.modal-content {
    overflow: initial;
    max-width: 900px !important;
    align-items: center;
    display: flex;
  }

  .compare-modal_photo {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 900px;

    .box-photo {
      text-align: center;
    }
    .photo {
      max-width: 900px;
      max-height: 700px;
      padding: 40px;
      box-sizing: border-box;
    }
  }

  @media only screen and (max-width: 926px) and (orientation: landscape) {
    ::v-deep.modal-content {
      max-width: 600px !important;
    }

    .compare-modal_photo {
      max-width: 600px;
      .photo {
        max-width: 600px;
        max-height: 400px;
        padding: 40px;
      }
    }
  }

  //Responsive mode
  @media only screen and (max-width: 780px) and (orientation: landscape) {
    .compare-modal_photo {
      .photo {
        max-width: 600px;
        max-height: 360px;
      }
    }
  }

  @media (max-width: 568px) {
    ::v-deep.modal-content {
      max-width: 500px !important;
    }

    .compare-modal_photo {
      max-width: 500px;
      .photo {
        max-width: 500px;
        max-height: 300px;
      }
    }
  }
}
</style>
