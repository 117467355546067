<template>
  <layout-stage backgroundColor="#889B7B" themeName="project">
    <template slot="nav-layout">
      <nav class="block-menu animate__animated animate__bounceInLeft">
        <router-link class="back-button" :to="{ name: 'Home' }" exact>
          <icon-back class="icon-menu" />
          Voltar
        </router-link>

        <ul class="block-menu_secondary">
          <li>
            <router-link :to="{ name: 'Project' }" exact>
              <icon-project />
              Projetistas
            </router-link>
          </li>
          <!-- <li>
            <router-link :to="{ name: 'ProjectSectorization' }" exact>
              <icon-sectorization />
              Setorização
            </router-link>
          </li> -->
          <li>
            <router-link :to="{ name: 'ProjectImplantation' }" exact>
              <icon-implantation />
              Implantação
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'ProjectDatasheet' }" exact>
              <icon-datasheet />
              Ficha técnica
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'ProjectDifferentials' }" exact>
              <icon-differentials />
              Diferenciais
            </router-link>
          </li>
        </ul>
      </nav>
    </template>
    <template slot="main-layout">
      <section class="block-content animate__animated animate__bounceInRight">
        <swiper ref="mySwiper" :options="swiperOptions">
          <swiper-slide v-for="(card, index) in MockCards" :key="index">
            <div class="block-slide" :class="{ active: index === activeItem }">
              <img class="slider-image" alt="" :src="card.imageCard" />
              <div class="slider-content">
                <h3 @click="openCard(index)">
                  {{ card.title }}
                  <span>
                    <img alt="" src="~@/assets/images/icon-arrow-right2.svg"
                  /></span>
                </h3>
              </div>
              <div class="slider-infos">
                <img alt="" class="logo" :src="card.infosLogo" />
                <img
                  alt=""
                  class="icon-close"
                  @click="closeCard()"
                  src="~@/assets/images/icon-close.svg"
                />
                <div class="slider-infos_box">
                  <p>{{ card.infosText }}</p>
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </section>
    </template>
  </layout-stage>
</template>

<script>
import LayoutStage from "@/components/LayoutStage";
import IconBack from "@/components/icons/IconBack";
import IconProject from "@/components/icons/IconProject";
// import IconSectorization from "@/components/icons/IconSectorization";
import IconImplantation from "@/components/icons/IconImplantation";
import IconDatasheet from "@/components/icons/IconDatasheet";
import IconDifferentials from "@/components/icons/IconDifferentials";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

export default {
  name: "Project",

  components: {
    LayoutStage,
    IconBack,
    IconProject,
    // IconSectorization,
    IconImplantation,
    IconDatasheet,
    IconDifferentials,
    Swiper,
    SwiperSlide,
  },

  data() {
    return {
      MockCards: [
        {
          title: "Arquitetura",
          imageCard: require("@/assets/images/image-card-01.png"),
          infosLogo: require("@/assets/images/image-logo-01.png"),
          infosText:
            "A arquitetura do Residencial Reserva das Palmeiras inspira-se nos novos padrões de habitar do período pós - pandemia. Afastamentos generosos entre as torres residenciais propiciam privacidade e melhor iluminação e ventilação naturais nos apartamentos. Estacionamentos descobertos e extensas áreas de lazer ao ar livre, com paisagismo diferenciado, estimulam um saudável convívio e interação entre os futuros moradores.",
        },
        {
          title: "Interiores",
          imageCard: require("@/assets/images/image-card-02.png"),
          infosLogo: require("@/assets/images/image-logo-02.png"),
          infosText:
            "Para atender as expectativas e necessidades do público de jovens casais, conectados ao mundo e novas tecnologias, foi proposta uma arquitetura que acompanha as tendências do design atual, através de uma unidade estética entre os ambientes no uso de materiais e texturas. \n O Salão de festas foi criado com um layout integrado e flexível, que consegue abrigar eventos de todos os tipos, atendendo as várias necessidades dos moradores. \n A madeira na cor clara, além de modernizar, traz aconchego, já as cores terrosas e naturais criam uma organização sensorial, integrando o salão interno ao externo.",
        },
        {
          title: "Paisagismo",
          imageCard: require("@/assets/images/image-card-03.png"),
          infosLogo: require("@/assets/images/image-logo-03.png"),
          infosText:
            "O Reserva das Palmeiras possui um lazer muito completo que foi elaborado para atender a todas as idades e proporcionar diversas atividades com objetivos de descanso, recreação, esporte, socialização e conexão com a natureza. \n Um grande diferencial é ter todo o lazer em terreno natural, o que nos permitiu trabalhar a vegetação para criar a ambiência de um bosque e, assim, trazer o verde para mais perto das pessoas. Junta- se a isso a posição privilegiada do terreno, em um ponto alto em relação à cidade, criando uma sensação de amplitude e liberdade além de pontos com belas visadas. \n Árvores com diversas cores de florações e renques de palmeiras ao longo dos caminhos compõem o cenário deste belo residencial.",
        },
      ],
      // showCard: true,
      activeItem: null,
      swiperOptions: {
        slidesPerView: 2.5,
        spaceBetween: 24,
      },
    };
  },

  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },

  methods: {
    openCard(i) {
      this.activeItem = i;
    },

    closeCard() {
      this.activeItem = null;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.swiper-container {
  padding-top: 10px;
}

.block-menu {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.block-content {
  margin-top: 50px;
  max-width: 1300px;
  width: 100%;

  .block-slide {
    width: 494px;
    height: 731px;
    border-radius: 6px 10px 77px 10px;
    position: relative;
    background-color: var(--pineGreen);

    .slider-image {
      width: 491px;
      height: 731px;
      border-radius: 6px 0 77px 10px;
      position: absolute;
      bottom: 0px;
      left: -10px;
      z-index: 10;
      transition: all 200ms ease-in-out;
    }

    .slider-infos {
      width: 481px;
      height: 731px;
      border-radius: 6px 10px 77px 10px;
      position: relative;
      background-color: var(--pineGreen);
      opacity: 0;
      z-index: 9;
      transition: all 200ms ease-in-out;
      padding: 38px 20px 38px 20px;
      box-sizing: border-box;

      img.logo {
        max-width: 150px;
        display: block;
        margin: 0 auto 15px;
      }

      .icon-close {
        position: absolute;
        top: 30px;
        right: 30px;
        width: 32px;
        cursor: pointer;
      }

      .slider-infos_box {
        height: 601px;
        padding: 12px 28px 0 18px;

        p {
          font-size: 20px;
          line-height: 26px;
          color: var(--white);
        }

        overflow-y: auto;

        &::-webkit-scrollbar {
          width: 5px;
        }

        &::-webkit-scrollbar-track {
          border-radius: 100px;
          background: rgba(0, 0, 0, 0.1);
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 100px;
          background: var(--amulet);
        }
      }
    }

    .slider-content {
      position: absolute;
      bottom: 30px;
      left: 30px;
      max-width: 320px;
      width: 100%;
      z-index: 11;
      opacity: 1;
      transition: all 200ms ease-in-out;

      h3 {
        color: var(--white);
        font-size: 30px;
        line-height: 39px;
        font-weight: var(--weight-bold);
        display: flex;
        align-items: center;
        cursor: pointer;

        span {
          margin-left: 12px;
          margin-top: 6px;
          img {
            width: 32px;
            height: 32px;
            position: relative;
          }
        }
      }
    }

    &.active {
      .slider-content {
        opacity: 0;
        z-index: 8;
        transition: all 200ms ease-out;
      }

      .slider-image {
        z-index: 9;
        bottom: 0px;
        border-radius: 0 10px 77px 0;
        left: 0px;
        transition: all 200ms ease-out;
      }

      .slider-infos {
        opacity: 1;
        z-index: 10;
        bottom: 0px;
        left: 5px;
        transition: all 200ms ease-in;
      }
    }
  }

  @media (max-width: 1680px) {
  }

  @media (max-width: 1440px) {
    max-width: 900px;

    .block-slide {
      width: 350px;
      height: 500px;

      .slider-image {
        width: 380px;
        height: 500px;
      }

      .slider-infos {
        width: 345px;
        height: 500px;

        h3 {
          font-size: 24px;
          line-height: 34px;
        }

        .icon-close {
          top: 24px;
          right: 24px;
          width: 22px;
        }

        .slider-infos_box {
          height: 300px;

          p {
            font-size: 16px;
            line-height: 22px;
          }
        }
      }

      .slider-content {
        h3 {
          font-size: 24px;
          line-height: 34px;
        }
      }
    }
  }

  @media (max-width: 1366px) {
  }

  @media (max-width: 1200px) {
  }

  @media (max-width: 1024px) {
  }

  @media only screen and (max-width: 926px) and (orientation: landscape) {
    position: fixed;
    right: 0;
    bottom: 10px;
    max-width: 530px;
    margin-top: 20px;

    .block-slide {
      width: 190px;
      height: 260px;
      border-radius: 20px;

      .slider-image {
        width: 190px;
        height: 260px;
        bottom: 5px;
        left: 5px;
        border-radius: 20px;
      }

      .slider-infos {
        width: 195px;
        height: 260px;
        padding: 10px;
        border-radius: 6px 10px 17px 10px;

        img.logo {
          max-width: 70px;
        }

        h3 {
          font-size: 14px;
          line-height: 24px;
        }

        .icon-close {
          top: 18px;
          right: 18px;
          width: 14px;
        }

        .slider-infos_box {
          height: 180px;
          padding: 12px 22px 0 22px;

          p {
            font-size: 16px;
            line-height: 22px;
          }
        }
      }

      .slider-content {
        bottom: 15px;
        left: 23px;
        max-width: 170px;

        h3 {
          font-size: 12px;
          line-height: 16px;

          span {
            img {
              width: 22px;
              height: 22px;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 780px) and (orientation: landscape) {
    max-width: 500px;
    margin-top: 20px;

    .block-slide {
      width: 160px;
      height: 185px;
      border-radius: 20px;

      .slider-image {
        width: 160px;
        height: 185px;
        object-fit: cover;
        bottom: 5px;
        left: 5px;
        border-radius: 20px;
      }

      .slider-infos {
        width: 160px;
        height: 185px;
        padding: 10px;

        h3 {
          font-size: 14px;
          line-height: 24px;
        }

        .icon-close {
          top: 18px;
          right: 18px;
          width: 14px;
        }

        .slider-infos_box {
          height: 110px;
          padding: 12px 22px 0 12px;

          p {
            font-size: 14px;
            line-height: 18px;
          }
        }
      }
    }
  }

  @media (max-width: 568px) {
    max-width: 380px;
    margin-top: 20px;

    .block-slide {
      width: 150px;
      height: 165px;

      .slider-image {
        width: 150px;
        height: 165px;
      }

      .slider-infos {
        width: 140px;
        height: 165px;
        padding: 10px;
        border-radius: 20px;

        .slider-infos_box {
          height: 80px;
          padding: 12px 22px 0 12px;
        }
      }
    }
  }
}

.back-button {
  color: var(--pineGreen);
  font-weight: var(--weight-regular);

  ::v-deep.icon-menu {
    stroke: var(--pineGreen);
  }
}

.block-menu_secondary {
  a {
    color: var(--pineGreen);

    ::v-deep.icon-menu {
      stroke: var(--pineGreen);
    }

    &:hover,
    &:active,
    &:focus,
    &.router-link-active {
      color: var(--amulet);
      background: var(--pineGreen);

      ::v-deep.icon-menu {
        stroke: var(--amulet);
      }
    }
  }
}
</style>
