<template>
  <layout-stage backgroundColor="#086769" themeName="about">
    <template slot="nav-layout">
      <nav class="block-menu animate__animated animate__bounceInLeft">
        <router-link class="back-button" :to="{ name: 'Home' }" exact>
          <icon-back class="icon-menu" />
          Voltar
        </router-link>

        <ul class="block-menu_secondary">
          <li>
            <router-link :to="{ name: 'About' }" exact>
              <icon-store />
              Conveniências
            </router-link>
          </li>
          <!-- <li>
            <router-link :to="{ name: 'AboutPhotos' }" exact>
              <icon-square />
              Fotos interativas
            </router-link>
          </li> -->
          <li>
            <router-link :to="{ name: 'About360' }" exact>
              <icon-compass />
              Mapa ilustrativo
            </router-link>
          </li>
        </ul>
      </nav>
    </template>
    <template slot="main-layout">
      <section class="block-content animate__animated animate__bounceInRight">
        <swiper ref="mySwiper" :options="swiperOptions">
          <swiper-slide v-for="(card, index) in MockPhoto" :key="index">
            <div class="block-slide">
              <img class="slider-image" alt="" :src="card.image" />
              <img
                class="slider-image"
                alt=""
                src="~@/assets/images/areas-comuns/bg-gradient-conveniencia.png"
              />
              <div class="slider-content">
                <h3>{{ card.title }}</h3>
                <p>{{ card.address }}</p>
                <p class="distance">
                  <img
                    class="icon-slider"
                    alt=""
                    src="~@/assets/images/icon-run.svg"
                  />
                  {{ card.distance }}
                </p>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </section>
    </template>
  </layout-stage>
</template>

<script>
import LayoutStage from "@/components/LayoutStage";
import IconBack from "@/components/icons/IconBack";
import IconCompass from "@/components/icons/IconCompass";
// import IconSquare from "@/components/icons/IconSquare";
import IconStore from "@/components/icons/IconStore";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

export default {
  name: "About",

  components: {
    LayoutStage,
    IconBack,
    IconCompass,
    // IconSquare,
    IconStore,
    Swiper,
    SwiperSlide,
  },

  data() {
    return {
      MockPhoto: [
        {
          title: "Jeronimo",
          address:
            "Av. Professor Mário Werneck, 1080 - Estoril, Belo Horizonte",
          distance: "10 min",
          image: require("@/assets/images/areas-comuns/conveniencias-01.png"),
        },
        {
          title: "Supermercados BH",
          address: "Rua Moisés Kalil, 320 - Buritis, Belo Horizonte",
          distance: "5 min",
          image: require("@/assets/images/areas-comuns/conveniencias-02.png"),
        },
        {
          title: "Da Boca",
          address:
            "Av. Professor Mário Werneck, 1973 - Buritis, Belo Horizonte",
          distance: "11 min",
          image: require("@/assets/images/areas-comuns/conveniencias-03.png"),
        },
        {
          title: "Mc Donald's",
          address: "Av. Eng. Carlos Goulart, 21 - Buritis, Belo Horizonte",
          distance: "8 min",
          image: require("@/assets/images/areas-comuns/conveniencias-04.png"),
        },
        {
          title: "UNI-BH",
          address:
            "Av. Professor Mário Werneck, 1685 - Buritis, Belo Horizonte",
          distance: "8 min",
          image: require("@/assets/images/areas-comuns/conveniencias-05.png"),
        },
        {
          title: "Shopping Paragem",
          address:
            "Av. Professor Mário Werneck, 1360 - Buritis, Belo Horizonte",
          distance: "10 min",
          image: require("@/assets/images/areas-comuns/conveniencias-06.png"),
        },
        {
          title: "Drogaria Araujo",
          address:
            "Av. Professor Mário Werneck, 1340 - Estoril, Belo Horizonte",
          distance: "9 min",
          image: require("@/assets/images/areas-comuns/conveniencias-07.png"),
        },
      ],
      swiperOptions: {
        slidesPerView: 2.5,
        spaceBetween: 24,
      },
    };
  },

  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.swiper-container {
  padding-top: 10px;
}
.block-menu {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.block-content {
  margin-top: 50px;
  max-width: 1300px;
  width: 100%;

  .block-slide {
    width: 494px;
    height: 732px;
    border-radius: 10px 10px 50px;
    position: relative;
    background-color: var(--amulet);

    .slider-image {
      width: 491px;
      height: 731px;
      border-radius: 0 10px 60px 0;
      position: absolute;
      bottom: 0;
      left: -8px;
    }

    .slider-content {
      position: absolute;
      bottom: 15px;
      left: 13px;
      max-width: 320px;
      width: 100%;

      h3 {
        color: var(--white);
        font-size: 20px;
        line-height: 24px;
        font-weight: var(--weight-bold);
        margin-bottom: 5px;
      }

      p {
        color: var(--white);
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 10px;
      }

      p.distance {
        display: flex;
        align-items: center;
        font-weight: var(--weight-bold);
        font-size: 20px;
        line-height: 24px;

        img {
          margin-right: 6px;
          width: 32px;
          height: 32px;
        }

        .slider-content {
          bottom: 20px;
          left: 25px;
        }
      }
    }
  }

  @media (max-width: 1680px) {
  }

  @media (max-width: 1440px) {
    max-width: 900px;

    .block-slide {
      width: 350px;
      height: 500px;

      .slider-image {
        width: 380px;
        height: 500px;
      }
    }
  }

  @media (max-width: 1366px) {
  }

  @media (max-width: 1200px) {
    margin-top: 100px;
  }

  @media (max-width: 1024px) {
  }

  @media only screen and (max-width: 926px) and (orientation: landscape) {
    max-width: 530px;
    margin-top: 20px;
    position: fixed;
    right: 0;
    bottom: 10px;

    .block-slide {
      width: 190px;
      height: 260px;
      border-radius: 20px;

      .slider-image {
        width: 190px;
        height: 260px;
        bottom: 5px;
        left: 5px;
        border-radius: 20px;
      }

      .slider-content {
        bottom: 15px;
        left: 23px;
        max-width: 170px;

        h3 {
          font-size: 14px;
          line-height: 18px;
        }

        p.distance {
          font-size: 14px;
          line-height: 18px;

          img {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 780px) and (orientation: landscape) {
    max-width: 480px;

    .block-slide {
      width: 150px;
      height: 190px;
      border-radius: 14px;

      .slider-image {
        width: 150px;
        height: 190px;
        border-radius: 14px;
      }

      .slider-content {
        max-width: 140px;
      }
    }
  }

  @media (max-width: 568px) {
    max-width: 380px;
    margin-top: 10px;

    .block-slide {
      width: 140px;
      height: 165px;

      .slider-image {
        width: 140px;
        height: 165px;
      }

      .slider-content {
        max-width: 110px;
      }
    }
  }
}

.back-button {
  color: var(--amulet);
  font-weight: var(--weight-regular);

  ::v-deep.icon-menu {
    stroke: var(--amulet);
  }
}

.block-menu_secondary {
  a {
    color: var(--amulet);

    ::v-deep.icon-menu {
      stroke: var(--amulet);
    }

    &:hover,
    &:active,
    &:focus,
    &.router-link-active {
      color: var(--pineGreen);
      background: var(--amulet);

      ::v-deep.icon-menu {
        stroke: var(--pineGreen);
      }
    }
  }

  @media (max-width: 1200px) {
    li:nth-child(2) {
      display: none;
    }
  }
}
</style>
