<template>
  <div class="layout-stage" :style="{ backgroundColor }">
    <div class="layout-stage_content" :class="'theme_' + themeName">
      <header class="animate__animated animate__fadeInDown">
        <logo-palmeiras />
        <logo-riva />
      </header>
      <main class="main">
        <slot name="nav-layout" />
        <slot name="main-layout" />
      </main>
    </div>
  </div>
</template>

<script>
import LogoPalmeiras from "@/components/LogoPalmeiras";
import LogoRiva from "@/components/LogoRiva";

export default {
  name: "LayoutStage",
  components: {
    LogoPalmeiras,
    LogoRiva,
  },

  props: {
    backgroundColor: {
      type: String,
      required: true,
    },
    themeName: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.layout-stage {
  height: 100%;

  .layout-stage_content {
    height: 100%;

    header {
      padding: 32px 100px;
      display: flex;
      width: 100%;
      justify-content: space-between;

      img.logo-goup {
        display: block;
        width: 240px;
        height: auto;
      }

      img.logo-riv {
        display: block;
        width: 176px;
        height: auto;
      }
    }

    main {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 0 0 0 100px;
      width: 100%;
      justify-content: space-between;
      flex: 1;
      height: 100%;
    }

    &.theme_about {
      background: url("~@/assets/images/bg-left-theme.png") bottom left
          no-repeat,
        url("~@/assets/images/bg-right-theme_about.svg") right bottom no-repeat,
        url("~@/assets/images/bg-top-theme.png") 30% top no-repeat;
      background-size: 480px, 1112px, auto;
    }

    &.theme_project {
      background: url("~@/assets/images/bg-left-theme_project.png") bottom left
          no-repeat,
        url("~@/assets/images/bg-right-theme_project.svg") right bottom
          no-repeat,
        url("~@/assets/images/bg-top-theme_project.png") 30% top no-repeat;
      background-size: 480px, 1112px, auto;

      header {
        ::v-deep .icon {
          fill: var(--white);
        }
      }
    }

    &.theme_common_areas {
      background: url("~@/assets/images/bg-left-theme.png") bottom left
          no-repeat,
        url("~@/assets/images/bg-right-theme_about.svg") right bottom no-repeat,
        url("~@/assets/images/bg-top-theme.png") 30% top no-repeat;
      background-size: 480px, 1112px, auto;

      header {
        ::v-deep .icon {
          fill: var(--white);
        }
      }
    }

    &.theme_units {
      background: url("~@/assets/images/bg-left-theme_units.png") bottom left
          no-repeat,
        url("~@/assets/images/bg-right-theme_units_units.svg") right bottom
          no-repeat,
        url("~@/assets/images/bg-top-theme_units.png") 30% top no-repeat;
      background-size: 480px, 1112px, auto;
    }

    &.theme_about,
    &.theme_project,
    &.theme_common_areas,
    &.theme_units {
      @media (max-width: 1680px) {
      }

      @media (max-width: 1440px) {
        background-size: 250px, 800px, auto;
      }

      @media (max-width: 1366px) {
      }

      @media (max-width: 1200px) {
        background-size: 300px, 760px, auto;
        background-position: bottom left, right 155px, 30% top;
      }

      @media (max-width: 1024px) {
        background-size: 300px, 690px, auto;
        background-position: bottom left, right bottom, 30% top;
      }

      @media only screen and (max-width: 926px) and (orientation: landscape) {
        background-size: 150px, 490px, 260px;
        background-position: bottom left, right bottom, 35% top;
      }

      @media only screen and (max-width: 780px) and (orientation: landscape) {
        background-size: 150px, 450px, 260px;
        background-position: bottom left, right bottom, 35% top;
      }

      @media (max-width: 569px) {
        background-size: 100px, 380px, 220px;
        background-position: bottom left, right bottom, 35% top;
      }
    }

    //resposive mode Global

    @media (max-width: 1680px) {
    }

    @media (max-width: 1440px) {
    }

    @media (max-width: 1366px) {
    }

    @media (max-width: 1200px) {
      header {
        padding: 32px 30px;
      }

      main {
        padding: 0 30px;
      }
    }

    @media (max-width: 1024px) {
    }

    @media only screen and (max-width: 926px) and (orientation: landscape) {
      header {
        padding: 10px 30px 0;

        ::v-deep svg {
          width: 110px;
        }
      }
    }
  }
}
</style>
