<template>
  <layout-stage backgroundColor="#086769" themeName="about">
    <template slot="nav-layout">
      <nav class="block-menu animate__animated animate__bounceInLeft">
        <router-link class="back-button" :to="{ name: 'Home' }" exact>
          <icon-back class="icon-menu" />
          Voltar
        </router-link>
        <div v-if="indexText === 0">
          <h3>Sobre o complexo</h3>
          <p>
            Um novo destino surge em Belo Horizonte e, com ele, uma nova
            proposta de se levar a vida. Agora você tem a oportunidade única de
            morar em um complexo de condomínios com toda infraestrutura que seu
            bem-estar merece. Em uma região privilegiada, um refúgio em meio à
            natureza para você se sentir em total harmonia com o verde e
            respirar mais qualidade de vida todos os dias. Esse projeto
            grandioso já é realidade. Aqui, sua felicidade faz parte de um todo.
          </p>
        </div>
        <div v-if="indexText === 1">
          <h3>Revitalização e infraestrutura</h3>
          <p>
            O Cidade Reserva dos Buritis está localizado em uma das regiões mais
            valorizadas da cidade. Um local que não para de se transformar e
            crescer. Um complexo com toda infraestrutura, urbanização,
            mobilidade e acesso a toda conveniência para o seu dia a dia. Agora
            você pode viver o melhor que a região do Buritis oferece com bem
            estar e qualidade de vida. Quem valoriza a felicidade, não pode
            perder a oportunidade de morar aqui.
          </p>
        </div>
        <div v-if="indexText === 2">
          <h3>Revitalização e infraestrutura</h3>
          <p>
            1 - Área verde pública a ser implantada<br /><br />

            2 - Área verde pública integrada ao parque Jacques Cousteau(Plantio
            de mais de 5 mil mudas de espécies nativas)<br /><br />

            3 - Nova via que possibilitará a interligação futura ao anel
            rodoviário<br /><br />

            4 - Alargamento implantado da rua Senhora do Pôrto
          </p>
        </div>
      </nav>
    </template>
    <template slot="main-layout">
      <section class="block-content animate__animated animate__bounceInRight">
        <swiper class="swiper" ref="mySwiper" :options="swiperOptions">
          <swiper-slide v-for="(card, index) in MockPhoto" :key="index">
            <div class="block-slide">
              <img class="slider-image" alt="" :src="card.image" />
              <div class="slider-content">
                <!--<h3>{{ card.title }}</h3>-->
              </div>
            </div>
          </swiper-slide>
          <div class="swiper-button-prev" slot="button-prev"></div>
          <div class="swiper-button-next" slot="button-next"></div>
        </swiper>
      </section>
    </template>
  </layout-stage>
</template>

<script>
import LayoutStage from "@/components/LayoutStage";
import IconBack from "@/components/icons/IconBack";
// import IconHome from "@/components/icons/IconHome";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

export default {
  name: "CommonAreas",

  components: {
    LayoutStage,
    IconBack,
    // IconHome,
    Swiper,
    SwiperSlide,
  },

  data() {
    return {
      indexText: 0,
      MockPhoto: [
        {
          title: "Foto 1",
          image: require("@/assets/images/imagem-sobre-complexo-01.png"),
        },
        {
          title: "Foto 2",
          image: require("@/assets/images/imagem-sobre-complexo-02.png"),
        },
        {
          title: "Foto 3",
          image: require("@/assets/images/imagem-sobre-complexo-03.png"),
        },
      ],
      swiperOptions: {
        slidesPerView: 1,
        spaceBetween: 10,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
          clickable: true,
        },
        onSlideChangeEnd: function () {
          this.onSwipe();
        },
      },
    };
  },
  mounted() {
    this.swiper.on("slideChange", () => {
      this.onSwipe(this);
    });
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },

  methods: {
    onSwipe(varuable) {
      this.indexText = varuable.swiper.activeIndex;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.swiper-container {
  padding-top: 20px;
  padding-bottom: 50px;
  padding-left: 44px;
}

::v-deep.swiper-button-prev,
.swiper-button-next {
  width: 70px !important;
  top: 48%;
}

.swiper-button-prev:after,
.swiper-container-rtl .swiper-button-next:after {
  color: var(--pineGreen);
  font-size: 22px;
  background: var(--amulet);
  height: 70px;
  width: 70px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 926px) and (orientation: landscape) {
    width: 50px;
    height: 50px;
  }
}

.swiper-button-next:after,
.swiper-container-rtl .swiper-button-next:after {
  color: var(--pineGreen);
  font-size: 22px;
  background: var(--amulet);
  height: 70px;
  width: 70px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 926px) and (orientation: landscape) {
    width: 50px;
    height: 50px;
  }
}

.block-menu {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;

  h3 {
    color: var(--amulet);
    font-size: 30px;
    line-height: 97.9%;
    margin-bottom: 45px;
  }

  p {
    max-width: 349px;
    font-size: 20px;
    line-height: 121.4%;
    color: var(--white);
  }

  @media only screen and (max-width: 926px) and (orientation: landscape) {
    h3 {
      font-size: 17px;
      line-height: 30px;
      margin-bottom: 10px;
    }
    p {
      font-size: 12px;
      max-width: 220px;
      line-height: 20px;
      overflow-y: auto;
      height: 120px;

      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-track {
        border-radius: 100px;
        background: rgba(0, 0, 0, 0.1);
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 100px;
        background: var(--sauvignon);
      }
    }
  }
}

.block-content {
  margin-top: 10px;
  max-width: 1311px;
  width: 100%;

  .block-slide {
    width: 1222px;
    height: 755px;
    border-radius: 30px;
    position: relative;
    background-color: var(--sauvignon);
    background: var(--amulet);
    border-radius: 10px 0 70px 15px;

    .block-slide__content {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 9;
      background: red;
      background: red;
    }

    .slider-image {
      width: 1207px;
      height: 755px;
      border-radius: 10px 0 77px 15px;
      position: absolute;
      object-fit: cover;
      bottom: 0px;
      left: 0px;
    }

    .slider-content {
      position: absolute;
      bottom: 20px;
      left: 40px;
      width: 100%;

      h3 {
        color: var(--white);
        font-size: 30px;
        line-height: 30px;
        font-weight: var(--weight-regular);
        margin-bottom: 5px;
      }
    }
  }

  //Responsive mode

  @media (max-width: 1680px) {
  }

  @media (max-width: 1440px) {
    max-width: 900px;

    .block-slide {
      width: 812px;
      height: 570px;

      .slider-image {
        width: 800px;
        height: 570px;
      }
    }
  }

  @media (max-width: 1366px) {
  }

  @media (max-width: 1200px) {
    margin-top: 50px;
    margin-left: -30px;
    max-width: 820px;

    .block-slide {
      width: 720px;
      height: 570px;

      .slider-image {
        width: 800px;
        height: 570px;
      }

      .slider-content {
        left: 175px;
      }
    }
  }

  @media (max-width: 1024px) {
    margin-top: 50px;
    margin-left: -40px;
    max-width: 740px;

    .block-slide {
      width: 640px;
      height: 500px;

      .slider-image {
        width: 800px;
        height: 500px;
      }

      .slider-content {
        left: 175px;
      }
    }
  }

  @media only screen and (max-width: 926px) and (orientation: landscape) {
    margin-top: 15px;
    margin-left: -40px;
    max-width: 440px;
    position: fixed;
    right: 0;
    bottom: 0px;

    .block-slide {
      width: 440px;
      height: 200px;

      .slider-image {
        width: 440px;
        height: 200px;
      }

      .slider-content {
        left: 65px;
        h3 {
          font-size: 18px;
        }
      }
    }
  }

  @media only screen and (max-width: 780px) and (orientation: landscape) {
    bottom: -25px;
    right: -10px;
    max-width: 480px;
    max-height: 310px;

    .block-slide {
      width: 400px;
      height: 210px;

      .slider-image {
        width: 400px;
        height: 210px;
      }

      .slider-content {
        bottom: 10px;
        left: 25px;
        h3 {
          font-size: 14px;
          line-height: 60px;
        }
      }
    }
  }

  @media (max-width: 568px) {
    margin-left: -40px;
    max-width: 400px;

    .block-slide {
      width: 300px;
      height: 160px;
      border-radius: 20px;

      .slider-image {
        width: 300px;
        height: 160px;
        border-radius: 20px;
      }

      .slider-content {
        left: 35px;
        h3 {
          font-size: 14px;
          line-height: 60px;
        }
      }
    }
  }
}

.back-button {
  color: var(--amulet);
  font-weight: var(--weight-regular);

  ::v-deep.icon-menu {
    stroke: var(--amulet);
  }
}

.block-menu_secondary {
  a {
    color: var(--amulet);

    ::v-deep.icon-menu {
      stroke: var(--amulet);
    }

    &:hover,
    &:active,
    &:focus,
    &.router-link-active {
      color: var(--pineGreen);
      background: var(--amulet);

      ::v-deep.icon-menu {
        stroke: var(--pineGreen);
      }
    }
  }
}
</style>
