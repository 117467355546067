<template>
  <layout-stage backgroundColor="#889B7B" themeName="project">
    <template slot="nav-layout">
      <nav class="block-menu animate__animated animate__bounceInLeft">
        <router-link class="back-button" :to="{ name: 'Home' }" exact>
          <icon-back class="icon-menu" />
          Voltar
        </router-link>

        <ul class="block-menu_secondary">
          <li>
            <router-link :to="{ name: 'Project' }" exact>
              <icon-project />
              Projetistas
            </router-link>
          </li>
          <!-- <li>
            <router-link :to="{ name: 'ProjectSectorization' }" exact>
              <icon-sectorization />
              Setorização
            </router-link>
          </li> -->
          <li>
            <router-link :to="{ name: 'ProjectImplantation' }" exact>
              <icon-implantation />
              Implantação
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'ProjectDatasheet' }" exact>
              <icon-datasheet />
              Ficha técnica
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'ProjectDifferentials' }" exact>
              <icon-differentials />
              Diferenciais
            </router-link>
          </li>
        </ul>
      </nav>
    </template>
    <template slot="main-layout">
      <section class="block-content animate__animated animate__bounceInRight">
        <div class="data-sheet">
          <div class="data-sheet_box">
            <div class="data-sheet_infos">
              <span>Ficha Técnica</span><br /><br />
              <ul>
                <li><span>NÚMERO DE TORRES:</span> 4.</li>
                <li>
                  <span>NÚMERO DE PAVIMENTOS:</span> BLOCOS 01 E 02: 13 PAVTOS.
                  BLOCOS 03 E 04: 12 PAVTOS.
                </li>
                <li><span>NÚMERO DE UNIDADES POR PAVIMENTO:</span> 8.</li>
                <li><span>TOTAL DE UNIDADES:</span> 400.</li>
                <li>
                  <span>ELEVADOR:</span> 2 ELEVADORES POR TORRE, CADA UM
                  ATENDENDO 4 UNIDADES POR ANDAR.
                </li>
              </ul>

              <span>Tipologia</span><br /><br />
              <ul>
                <li>
                  <span>APARTAMENTOS 1 QUARTO TÉRREO (GARDEN):</span> 101 E 108
                  = 38,75 +32,21 (ÁREA PRIVATIVA).<br />104 E 105 = 38,96+32,21
                  (ÁREA PRIVATIVA).
                </li>
                <li>
                  <span>APARTAMENTOS DE 3 QUARTOS TÉRREO (GARDEN):</span> 102,
                  103, 106 E 107 = 67,40 + 38,31 (ÁREA PRIVATIVA).
                </li>
                <li><span>APARTAMENTOS 2 QUARTOS:</span> TIPO 47,70M2.</li>
                <li><span>APARTAMENTOS 3 QUARTOS:</span> TIPO 67,40M2.</li>
                <li>
                  <span>VAGAS DE ESTACIONAMENTO:</span> 568 VAGAS VINCULADAS ÀS
                  UNIDADES (1 OU 2 VAGAS POR APARTAMENTO)
                </li>
                <li>
                  <span>VAGAS PARA VISITANTES:</span> 6, SENDO UMA COM TOMADA
                  PARA CARRO ELÉTRICO.
                </li>
                <li>
                  <span>ÁREA DE LAZER:</span> SALÃO DE FESTAS, PLAYGROUNDS,
                  PISCINA ADULTO, PISCINA INFANTIL, SOLARIUM, PERGOLADOS,
                  BICICLETÁRIO, REDÁRIO, CHURRASQUEIRAS, QUADRA INFANTIL, QUADRA
                  GRAMADA E GINÁSTICA DESCOBERTA.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </template>
  </layout-stage>
</template>

<script>
import LayoutStage from "@/components/LayoutStage";
import IconBack from "@/components/icons/IconBack";
import IconProject from "@/components/icons/IconProject";
// import IconSectorization from "@/components/icons/IconSectorization";
import IconImplantation from "@/components/icons/IconImplantation";
import IconDatasheet from "@/components/icons/IconDatasheet";
import IconDifferentials from "@/components/icons/IconDifferentials";

export default {
  name: "ProjectDatasheet",

  components: {
    LayoutStage,
    IconBack,
    IconProject,
    // IconSectorization,
    IconImplantation,
    IconDatasheet,
    IconDifferentials,
  },
};
</script>

<style lang="scss" scoped>
.block-menu {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.block-content {
  margin-top: 10px;
  max-width: 1300px;
  margin-right: 10px;
  width: 100%;
  border-radius: 30px 0 70px 15px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  background: url("~@/assets/images/bg-datasheet.png") center center no-repeat;

  .data-sheet {
    .data-sheet_box {
      padding: 57px;
      background-color: var(--pineGreen);
      border-radius: 30px 0 70px 15px;
      height: 767px;
      width: 538px;
      .data-sheet_infos {
        overflow-y: auto;
        height: 650px;

        span {
          color: var(--sauvignon);
          font-weight: var(--weight-bold);
          font-size: 18px;
          margin-bottom: 16px;
        }

        p {
          font-size: 20px;
          line-height: 26px;
          color: var(--sauvignon);
          margin-bottom: 16px;

          span {
            font-weight: var(--weight-bold);
          }
        }

        ul {
          list-style-type: circle;
          margin-bottom: 32px;

          li {
            font-size: 20px;
            line-height: 26px;
            color: var(--sauvignon);
            margin: 0 0 12px 20px;
            list-style-type: circle;
          }
        }

        &::-webkit-scrollbar {
          width: 5px;
        }

        &::-webkit-scrollbar-track {
          border-radius: 100px;
          background: rgba(0, 0, 0, 0.1);
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 100px;
          background: var(--sauvignon);
        }
      }
    }
  }

  //Responsive mode

  @media (max-width: 1680px) {
  }

  @media (max-width: 1440px) {
    max-width: 900px;

    .image-datasheet {
      max-width: 490px;
      height: 700px;
    }

    .data-sheet {
      .data-sheet_box {
        padding: 38px;
        height: 560px;
        width: 380px;

        .data-sheet_infos {
          height: 460px;

          p {
            font-size: 16px;
            line-height: 22px;
            margin-bottom: 12px;
          }

          ul {
            li {
              font-size: 16px;
              line-height: 22px;
              margin-bottom: 12px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1366px) {
  }

  @media (max-width: 1200px) {
    .image-datasheet {
      margin-left: 30px;
    }
  }

  @media (max-width: 1024px) {
  }

  @media only screen and (max-width: 926px) and (orientation: landscape) {
    max-width: 530px;
    position: fixed;
    right: 0;
    bottom: 10px;

    .image-datasheet {
      max-width: 290px;
      height: 350px;
      margin-left: 0px;
    }

    .data-sheet {
      margin-top: 0px;

      .data-sheet_box {
        padding: 18px;
        height: 270px;
        width: 270px;
        margin-right: 20px;

        .data-sheet_infos {
          height: 220px;

          p {
            font-size: 14px;
            line-height: 16px;
            margin-bottom: 12px;

            .icon-data-sheet {
              margin-right: 10px;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 780px) and (orientation: landscape) {
    max-width: 478px;
    max-height: 280px;

    .image-datasheet {
      max-width: 250px;
      height: 330px;
    }

    .data-sheet {
      .data-sheet_box {
        height: 240px;
        width: 210px;

        .data-sheet_infos {
          height: 210px;

          p {
            font-size: 12px;
            line-height: 14px;
            margin-bottom: 12px;

            .icon-data-sheet {
              margin-right: 10px;
            }
          }

          ul li {
            font-size: 12px;
            line-height: 14px;
            margin-bottom: 12px;
          }
        }
      }
    }
  }

  @media (max-width: 568px) {
    max-width: 380px;

    .image-datasheet {
      max-width: 200px;
      height: 280px;
    }

    .data-sheet {
      .data-sheet_box {
        height: 180px;

        .data-sheet_infos {
          height: 140px;

          p {
            font-size: 14px;
            line-height: 16px;
            margin-bottom: 12px;

            .icon-data-sheet {
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
}

.back-button {
  color: var(--pineGreen);
  font-weight: var(--weight-regular);

  ::v-deep.icon-menu {
    stroke: var(--pineGreen);
  }
}

.block-menu_secondary {
  a {
    color: var(--pineGreen);

    ::v-deep.icon-menu {
      stroke: var(--pineGreen);
    }

    &:hover,
    &:active,
    &:focus,
    &.router-link-active {
      color: var(--amulet);
      background: var(--pineGreen);

      ::v-deep.icon-menu {
        stroke: var(--amulet);
      }
    }
  }
}
</style>
