<template>
  <layout-stage backgroundColor="#889B7B" themeName="project">
    <template slot="nav-layout">
      <nav class="block-menu animate__animated animate__bounceInLeft">
        <router-link class="back-button" :to="{ name: 'Home' }" exact>
          <icon-back class="icon-menu" />
          Voltar
        </router-link>

        <ul class="block-menu_secondary">
          <li>
            <router-link :to="{ name: 'Project' }" exact>
              <icon-project />
              Projetistas
            </router-link>
          </li>
          <!-- <li>
            <router-link :to="{ name: 'ProjectSectorization' }" exact>
              <icon-sectorization />
              Setorização
            </router-link>
          </li> -->
          <li>
            <router-link :to="{ name: 'ProjectImplantation' }" exact>
              <icon-implantation />
              Implantação
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'ProjectDatasheet' }" exact>
              <icon-datasheet />
              Ficha técnica
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'ProjectDifferentials' }" exact>
              <icon-differentials />
              Diferenciais
            </router-link>
          </li>
        </ul>
      </nav>
    </template>
    <template slot="main-layout">
      <section class="block-content animate__animated animate__bounceInRight">
        <swiper class="swiper" ref="mySwiper" :options="swiperOptions">
          <swiper-slide class="slide slide-1">
            <div class="differentials">
              <div class="differentials_box">
                <h3>Reserva Eco</h3>
                <div class="differentials_infos">
                  <p>
                    <img
                      class="icon-differentials"
                      src="~@/assets/images/icon-differentials_01.svg"
                      alt=""
                    />
                    <span
                      >Utilização de Bacia sanitária com caixa acoplada/duplo
                      acionamento.</span
                    >
                  </p>

                  <p>
                    <img
                      class="icon-differentials"
                      src="~@/assets/images/icon-differentials_02.svg"
                      alt=""
                    />
                    <span
                      >Utilização de sensor de presença nos halls e circulações
                      das torres.</span
                    >
                  </p>

                  <p>
                    <img
                      class="icon-differentials"
                      src="~@/assets/images/icon-differentials_03.svg"
                      alt=""
                    />
                    <span
                      >Torneira com fechamento automático ou sensor nas áreas
                      comuns.</span
                    >
                  </p>

                  <p>
                    <img
                      class="icon-differentials"
                      src="~@/assets/images/icon-differentials_04.svg"
                      alt=""
                    />
                    <span>Sistema de aproveitamento de água de chuva.</span>
                  </p>

                  <p>
                    <img
                      class="icon-differentials"
                      src="~@/assets/images/icon-differentials_05.svg"
                      alt=""
                    />
                    <span>Bicicletário</span>
                  </p>

                  <p>
                    <img
                      class="icon-differentials"
                      src="~@/assets/images/icon-differentials_06.svg"
                      alt=""
                    />
                    <span
                      >Abrigo de resíduos com infraestrutura para coleta
                      seletiva.</span
                    >
                  </p>

                  <p>
                    <img
                      class="icon-differentials"
                      src="~@/assets/images/icon-differentials_07.svg"
                      alt=""
                    />
                    <span>Paisagismo com utilização de espécies nativas.</span>
                  </p>

                  <p>
                    <img
                      class="icon-differentials"
                      src="~@/assets/images/icon-differentials_08.svg"
                      alt=""
                    />
                    <span
                      >Torneiras com arejadores nos apartamentos (redução do
                      consumo).</span
                    >
                  </p>

                  <p>
                    <img
                      class="icon-differentials"
                      src="~@/assets/images/icon-differentials_09.svg"
                      alt=""
                    />
                    <span>Lâmpadas de LED nas áreas comuns.</span>
                  </p>

                  <p>
                    <img
                      class="icon-differentials"
                      src="~@/assets/images/icon-differentials_10.svg"
                      alt=""
                    />
                    <span
                      >Estacionamento iluminado com luminárias de LED com relé
                      fotovoltáico.</span
                    >
                  </p>

                  <p>
                    <img
                      class="icon-differentials"
                      src="~@/assets/images/icon-differentials_11.svg"
                      alt=""
                    />
                    <span>Elevadores inteligentes.</span>
                  </p>

                  <p>
                    <img
                      class="icon-differentials"
                      src="~@/assets/images/icon-differentials_12.svg"
                      alt=""
                    />
                    <span
                      >Previsão de medição individualizada de água e gás.</span
                    >
                  </p>
                </div>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide class="slide slide-2">
            <div class="differentials differentials-2">
              <div class="differentials_box">
                <h3>Reserva confort</h3>
                <div class="differentials_infos">
                  <p>
                    <img
                      class="icon-differentials"
                      src="~@/assets/images/icon-differentials2_01.svg"
                      alt=""
                    />
                    <span>
                      2 elevadores por torre, cada um atendendo apenas 4
                      unidades por andar.
                    </span>
                  </p>

                  <p>
                    <img
                      class="icon-differentials"
                      src="~@/assets/images/icon-differentials2_02.svg"
                      alt=""
                    />
                    <span>
                      Infraestrutura para ar-condicionado nas áreas comuns
                    </span>
                  </p>

                  <p>
                    <img
                      class="icon-differentials"
                      src="~@/assets/images/icon-differentials2_03.svg"
                      alt=""
                    />
                    <span> Tomada para recarga de carro elétrico. </span>
                  </p>

                  <p>
                    <img
                      class="icon-differentials"
                      src="~@/assets/images/icon-differentials2_04.svg"
                      alt=""
                    />
                    <span>Infraestrutura para Wifi nas áreas comuns.</span>
                  </p>

                  <p>
                    <img
                      class="icon-differentials"
                      src="~@/assets/images/icon-differentials2_05.svg"
                      alt=""
                    />
                    <span
                      >Fachada revestida, parcialmente, com eco granito.</span
                    >
                  </p>

                  <p>
                    <img
                      class="icon-differentials"
                      src="~@/assets/images/icon-differentials2_06.svg"
                      alt=""
                    />
                    <span>Locker para recebimento de encomendas.</span>
                  </p>

                  <p>
                    <img
                      class="icon-differentials"
                      src="~@/assets/images/icon-differentials2_07.svg"
                      alt=""
                    />
                    <span>Clausura para maior segurança no acesso.</span>
                  </p>
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
        <div class="box-controllers">
          <div class="pagination-static">
            <div v-for="(menu, index) in mockPagination" :key="index">
              <div
                @click="prevSwiper(0)"
                class="swiper-button-prev"
                slot="button-prev"
              ></div>
              <button
                class="pagination-button"
                v-if="index === 0"
                :class="{ active: index === setIndex }"
                @click="prevSwiper(index)"
              >
                {{ menu }}
              </button>
              <button
                class="pagination-button"
                :class="{ active: index === setIndex }"
                v-if="index === 1"
                @click="nextSwiper(index)"
              >
                {{ menu }}
              </button>
            </div>
            <div
              @click="nextSwiper(1)"
              class="swiper-button-next"
              slot="button-next"
            ></div>
          </div>
        </div>
      </section>
    </template>
  </layout-stage>
</template>

<script>
import LayoutStage from "@/components/LayoutStage";
import IconBack from "@/components/icons/IconBack";
import IconProject from "@/components/icons/IconProject";
// import IconSectorization from "@/components/icons/IconSectorization";
import IconImplantation from "@/components/icons/IconImplantation";
import IconDatasheet from "@/components/icons/IconDatasheet";
import IconDifferentials from "@/components/icons/IconDifferentials";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

export default {
  name: "ProjectDifferentials",

  components: {
    LayoutStage,
    IconBack,
    IconProject,
    // IconSectorization,
    IconImplantation,
    IconDatasheet,
    IconDifferentials,
    Swiper,
    SwiperSlide,
  },

  data() {
    return {
      mockPagination: ["Reserva Eco", "Reserva confort"],
      setIndex: 0,
      swiperOptions: {
        slidesPerView: 1,
        spaceBetween: 50,
        simulateTouch: false,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
          clickable: true,
        },
      },
    };
  },

  methods: {
    prevSwiper(i) {
      this.setIndex = i;
      this.$refs.mySwiper.$swiper.slidePrev();
    },
    nextSwiper(i) {
      this.setIndex = i;
      this.$refs.mySwiper.$swiper.slideNext();
    },
  },
};
</script>

<style lang="scss" scoped>
/* Swiper Styles*/
::v-deep.swiper-container {
  padding-top: 20px;
  padding-bottom: 50px;
  padding-left: 0px;

  @media only screen and (max-width: 926px) and (orientation: landscape) {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  @media only screen and (max-width: 780px) and (orientation: landscape) {
  }

  @media (max-width: 568px) {
  }
}

.box-controllers {
  position: absolute;
  height: 146px;
  width: 620px;
  bottom: 200px;
  right: 0;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;

  .pagination-static {
    display: flex;
    margin-top: 15px;

    .pagination-button {
      color: var(--amulet);
      font-size: 20px;
      line-height: 20px;
      border: none;
      border-radius: none;
      text-decoration: none;
      cursor: pointer;
      background: var(--sauvignon);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0 4px;
      height: 100px;
      width: 180px;
      border-radius: 100px;

      &.active {
        color: var(--sauvignon);
        font-weight: var(--weight-bold);
        background: var(--pineGreen);

        &:after {
          content: "";
          border-radius: 100%;
          width: 5px;
          height: 5px;
          background: var(--sauvignon);
          position: absolute;
          margin-top: 18px;
        }
      }
    }
  }

  .swiper-button-next {
    right: 0;
    left: auto;
    height: 146px;
    top: 30px;
    width: 120px;
    display: none !important;
  }

  .swiper-button-prev {
    left: 0;
    right: auto;
    height: 146px;
    top: 30px;
    width: 120px;
    display: none !important;
  }

  .swiper-button-prev:after,
  .swiper-container-rtl .swiper-button-next:after {
    color: var(--blackRose);
    font-size: 22px;
  }

  .swiper-button-next:after,
  .swiper-container-rtl .swiper-button-next:after {
    color: var(--blackRose);
    font-size: 22px;
  }

  //Responsive mode

  @media (max-width: 1680px) {
  }

  @media (max-width: 1440px) {
    height: 100px;
    width: 400px;
    bottom: 170px;

    .pagination-static {
      &::before {
        height: 70px;
        top: 15px;
        left: 81px;
      }

      &::after {
        height: 70px;
        top: 15px;
        right: 81px;
      }

      .pagination-button {
        font-size: 14px;
        line-height: 14px;
        height: 80px;
        width: 140px;
      }
    }

    .swiper-button-next {
      height: 100px;
      top: 22px;
      width: 80px;
    }

    .swiper-button-prev {
      height: 100px;
      top: 22px;
      width: 80px;
    }
  }

  @media (max-width: 1366px) {
  }

  @media (max-width: 1200px) {
    height: 70px;
    width: 163px;
    bottom: 135px;
    right: 100px;

    .swiper-button-prev,
    .swiper-button-next {
      height: 80px;
    }
    .pagination-static {
      .pagination-button {
        display: none;
      }
    }
  }

  @media (max-width: 1024px) {
    bottom: 134px;
    right: 180px;
  }

  @media only screen and (max-width: 926px) and (orientation: landscape) {
    bottom: -10px;
    right: 0px;
  }
}
/* Swiper Styles*/
.block-menu {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.block-content {
  margin-top: 10px;
  max-width: 1300px;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  justify-content: space-between;
  margin-right: 10px;

  .slide {
    overflow: hidden;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    border-radius: 30px 0 70px 30px;

    &.slide-1 {
      background: url("~@/assets/images/bg-diferenciais-1.png") center center
        no-repeat;
    }

    &.slide-2 {
      background: url("~@/assets/images/bg-diferenciais-2.png") center center
        no-repeat;
    }
  }

  .differentials {
    .differentials_box {
      padding: 57px;
      background-color: var(--pineGreen);
      border-radius: 30px 0 70px 15px;
      height: 767px;
      width: 538px;
      box-sizing: border-box;

      h3 {
        font-weight: bold;
        color: var(--sauvignon);
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 40px;
      }

      .differentials-logo {
        max-width: 150px;
        margin-bottom: 30px;
      }

      .differentials_infos {
        overflow-y: auto;
        height: 650px;

        p {
          font-size: 20px;
          line-height: 26px;
          color: var(--sauvignon);
          margin-bottom: 40px;
          display: flex;
          align-items: center;
          padding-right: 10px;

          &:last-child {
            margin-bottom: 0;
          }

          .icon-differentials {
            margin-right: 20px;
          }

          span {
            padding-right: 10px;
          }
        }

        &::-webkit-scrollbar {
          width: 5px;
        }

        &::-webkit-scrollbar-track {
          border-radius: 100px;
          background: rgba(0, 0, 0, 0.1);
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 100px;
          background: var(--sauvignon);
        }
      }
    }

    &.differentials-2 {
      .differentials_box {
        background: var(--sauvignon);

        h3 {
          color: var(--pineGreen);
        }

        .differentials_infos {
          p {
            color: var(--pineGreen);
          }

          &::-webkit-scrollbar {
            width: 5px;
          }

          &::-webkit-scrollbar-track {
            border-radius: 100px;
            background: rgba(0, 0, 0, 0.1);
          }

          &::-webkit-scrollbar-thumb {
            border-radius: 100px;
            background: var(--pineGreen);
          }
        }
      }
    }
  }

  //Responsive mode

  @media (max-width: 1680px) {
  }

  @media (max-width: 1440px) {
    max-width: 900px;

    .differentials {
      .differentials_box {
        padding: 38px;
        height: 550px;
        width: 380px;

        .differentials_infos {
          height: 380px;

          p {
            font-size: 16px;
            line-height: 22px;
            margin-bottom: 12px;
          }

          ul {
            li {
              font-size: 16px;
              line-height: 22px;
              margin-bottom: 12px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1366px) {
  }

  @media (max-width: 1200px) {
  }

  @media (max-width: 1024px) {
  }

  @media only screen and (max-width: 926px) and (orientation: landscape) {
    max-width: 530px;
    position: fixed;
    right: 0;
    bottom: 10px;

    .differentials {
      margin-top: 0px;

      .differentials_box {
        padding: 18px;
        height: 270px;
        width: 270px;
        margin-right: 20px;

        .differentials-logo {
          max-width: 100px;
          margin-bottom: 20px;
        }

        .differentials_infos {
          height: 170px;

          p {
            font-size: 14px;
            line-height: 16px;
            margin-bottom: 12px;

            .icon-differentials {
              margin-right: 10px;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 780px) and (orientation: landscape) {
    max-width: 478px;
    max-height: 240px;

    .differentials {
      .differentials_box {
        height: 240px;
        width: 210px;

        .differentials-logo {
          margin-bottom: 15px;
        }

        .differentials_infos {
          height: 140px;

          p {
            font-size: 14px;
            line-height: 16px;
            margin-bottom: 12px;

            .icon-differentials {
              margin-right: 10px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 568px) {
    max-width: 380px;

    .differentials {
      .differentials_box {
        height: 180px;

        .differentials-logo {
          margin-bottom: 5px;
        }

        .differentials_infos {
          height: 110px;

          p {
            font-size: 14px;
            line-height: 16px;
            margin-bottom: 12px;

            .icon-differentials {
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
}

.back-button {
  color: var(--pineGreen);
  font-weight: var(--weight-regular);

  ::v-deep.icon-menu {
    stroke: var(--pineGreen);
  }
}

.block-menu_secondary {
  a {
    color: var(--pineGreen);

    ::v-deep.icon-menu {
      stroke: var(--pineGreen);
    }

    &:hover,
    &:active,
    &:focus,
    &.router-link-active {
      color: var(--amulet);
      background: var(--pineGreen);

      ::v-deep.icon-menu {
        stroke: var(--amulet);
      }
    }
  }
}
</style>
