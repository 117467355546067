<template>
  <div class="home">
    <header class="animate__animated">
      <img
        class="logo-palmeiras animate__animated animate__fadeIn"
        alt="Reserva das Palmeiras Logo"
        src="~@/assets/images/logo-reserva_palmeiras.svg"
      />
      <img
        class="logo-riv animate__animated animate__fadeIn"
        alt="Riva Logo"
        src="~@/assets/images/logo-riva_green.svg"
      />
    </header>
    <main>
      <main-menu />

      <section class="block-youtube animate__animated animate__bounceInRight">
        <a class="block-youtube_button" @click="isModalHomeActive = true">
          <span
            class="
              animate__animated
              animate__pulse
              animate__delay-1s
              animate__infinite
            "
          >
            <img alt="" src="~@/assets/images/icon-play.svg" />
          </span>
          Ver vídeo
        </a>
        <p>A felicidade está na sua nova rotina.</p>
      </section>
    </main>

    <b-modal
      :destroy-on-hide="true"
      class="home-modal"
      v-model="isModalHomeActive"
    >
      <div class="modal-yt">
        <div class="modal-yt_header">
          <img
            class="logo-palmeiras"
            alt="GoUp Logo"
            src="~@/assets/images/logo-reserva_palmeiras.svg"
          />
          <img
            class="logo-riva"
            alt="Riv Logo"
            src="~@/assets/images/logo-riv.svg"
          />
        </div>
        <div class="modal-yt_main">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/FJwr__t_OPc"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </b-modal>

    <b-modal
      :destroy-on-hide="true"
      :active="true"
      class="home-modal"
      @close="openFullScreen()"
      v-model="isFullScreenActive"
    >
      <div class="modal-yt">
        <div class="modal-yt_main">
          <div class="data-sheet_box">
            <h3>Bem-vindo</h3>
            <div class="slider-infos_box">
              <p>
                Para uma melhor navegação é necessário iniciar a aplicaçã em
                modo full screen!
              </p>
              <button @click="openFullScreen()" class="button-full-screen">
                Iniciar navegação
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import MainMenu from "@/components/MainMenu.vue";

export default {
  name: "Home",

  components: {
    MainMenu,
  },

  data() {
    return {
      isModalHomeActive: false,
      isFullScreenActive: this.checkFullScreen(),
    };
  },
  methods: {
    openFullScreen() {
      var elem = document.documentElement;
      if (elem.requestFullscreen) {
        /* Chrome */
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) {
        /* Safari */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        /* IE11 */
        elem.msRequestFullscreen();
      } else if (elem.mozFullScreenElement) {
        /* Moz */
        elem.mozFullScreenElement();
      }
      this.isFullScreenActive = false;
    },
    checkFullScreen() {
      var fullscreenElement =
        document.fullscreenElement ||
        document.mozFullScreenElement ||
        document.webkitFullscreenElement ||
        document.msFullscreenElement;
      return !fullscreenElement;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/modal";

.home {
  background: url("~@/assets/images/bg-home.png") top right no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;

  header {
    padding: 32px 100px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    position: relative;
    z-index: 10;

    img.logo-palmeiras {
      display: block;
      width: 280px;
      height: auto;
    }

    img.logo-riva {
      display: block;
      width: 146px;
      height: auto;
    }
  }

  main {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0 53px;
    width: 100%;
    justify-content: space-between;
    flex: 1;

    .block-youtube {
      position: fixed;
      bottom: 0;
      right: 0;
      margin: 0 0 0 0;
      height: 740px;
      width: 910px;
      background: url("~@/assets/images/bg-title_home.png") bottom right
        no-repeat;
      background-size: 910px auto;

      .block-youtube_button {
        position: absolute;
        right: 320px;
        top: 70px;
        z-index: 9;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--chelseaCucumber);
        font-weight: var(--weight-semibold);
        font-size: 20px;
        line-height: 20px;
        background: var(--pineGreen);
        border-radius: 10px;
        max-width: 204px;
        width: 100%;
        height: 60px;

        img {
          margin-right: 12px;
        }
      }

      p {
        font-weight: var(--weight-regular);
        color: var(--white);
        font-size: 54px;
        line-height: 59px;
        max-width: 301px;
        position: absolute;

        bottom: 100px;
        right: 210px;
        z-index: 9;
      }
    }
  }

  @media (max-width: 1680px) {
    main .block-youtube {
      margin: 0 0 0 0;
      height: 500px;
      width: 610px;
      background: url("~@/assets/images/bg-title_home.png") bottom right
        no-repeat;
      background-size: 610px auto;

      p {
        bottom: 40px;
        right: 90px;
      }
    }
  }

  @media (max-width: 1440px) {
  }

  @media (max-width: 1366px) {
  }

  @media (max-width: 1200px) {
    header {
      padding: 32px 30px;
    }

    main {
      padding: 0 30px;

      .block-youtube {
        max-width: 370px;
        .block-youtube_button {
          left: 150px;
          top: 205px;
        }
        p {
          right: -10px;
          bottom: -90px;
        }
      }
    }
  }

  @media (max-width: 1024px) {
  }

  @media only screen and (max-width: 926px) and (orientation: landscape) {
    background: url("~@/assets/images/bg-home.png") bottom right no-repeat;
    background-size: cover;
    header {
      padding: 20px 70px;

      img.logo-palmeiras,
      img.logo-riv {
        width: 130px;
      }
    }

    main {
      padding: 0 30px;

      .block-youtube {
        background-size: 360px auto;

        .block-youtube_button {
          right: -20px;
          top: 245px;
          font-size: 12px;
          width: 140px;
          height: 40px;

          span {
            width: 20px;
            height: 20px;
            margin-right: 6px;
            img {
              height: 12px;
            }
          }
        }
        p {
          right: 30px;
          bottom: 45px;
          font-size: 20px;
          line-height: 22px;
          max-width: 140px;
        }
      }
    }
  }

  @media only screen and (max-width: 780px) and (orientation: landscape) {
    background-size: cover;
    main {
      .block-youtube {
        margin-top: -20px;
      }
    }
  }
}

.modal-yt {
  max-width: 1100px;
  width: 100%;

  .modal-yt_header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 38px;

    .logo-palmeiras {
      width: 270px;
      height: auto;
      margin-right: 38px;
    }

    .logo-riv {
      width: 117px;
      height: auto;
      margin-left: 38px;
    }
  }

  .modal-yt_main {
    iframe {
      border-radius: 30px;
      width: 100%;
      height: 550px;
    }

    .data-sheet_box {
      padding: 57px;
      background-color: rgb(8, 103, 105);
      height: 434px;
      width: 549px;
      margin: auto;
      border-radius: 30px;

      h3 {
        font-size: 30px;
        line-height: 39px;
        color: var(--amulet);
        font-weight: var(--weight-bold);
        text-align: center;
        padding: 34px 0 0 0;
      }

      .slider-infos_box {
        padding: 32px 18px 0 18px;

        p {
          font-size: 20px;
          line-height: 26px;
          text-align: center;
          color: var(--amulet);
        }

        .button-full-screen {
          background: var(--amulet);
          height: 50px;
          display: flex;
          margin: auto;
          top: 52px;
          align-items: center;
          justify-content: center;
          color: var(--pineGreen);
          font-size: 16px;
          line-height: 20px;
          border: none;
          border-radius: 100px;
          width: 270px;
          cursor: pointer;
          position: relative;
          z-index: 11;
        }
      }
    }
  }

  @media only screen and (max-width: 926px) and (orientation: landscape) {
    max-width: 600px;

    .modal-yt_main {
      iframe {
        height: 280px;
      }
    }
  }
}
</style>
