<template>
  <layout-stage backgroundColor="#086769" themeName="about">
    <template slot="nav-layout">
      <nav class="block-menu animate__animated animate__bounceInLeft">
        <router-link class="back-button" :to="{ name: 'Home' }" exact>
          <icon-back class="icon-menu" />
          Voltar
        </router-link>

        <ul class="block-menu_secondary">
          <li>
            <router-link :to="{ name: 'About' }" exact>
              <icon-store />
              Conveniências
            </router-link>
          </li>
          <!-- <li>
            <router-link :to="{ name: 'AboutPhotos' }" exact>
              <icon-square />
              Fotos interativas
            </router-link>
          </li> -->
          <li>
            <router-link :to="{ name: 'About360' }" exact>
              <icon-compass />
              Mapa ilustrativo
            </router-link>
          </li>
        </ul>
      </nav>
    </template>
    <template slot="main-layout">
      <section class="block-content animate__animated animate__bounceInRight">
        <div class="block-content-center">
          <div class="col-list">
            <ul>
              <li v-for="(item, index) in mockImplantation" :key="index">
                <span
                  v-if="item.imageModal"
                  class="clickable"
                  @click="openPhoto(item.imageModal)"
                >
                  <span class="col-list_counter">{{ index + 1 }}</span>
                  {{ item.name }}
                </span>

                <span v-else>
                  <span class="col-list_counter">{{ index + 1 }}</span>
                  {{ item.name }}
                </span>
              </li>
            </ul>
          </div>
          <div class="col-image-container">
            <img src="~@/assets/images/ilustracao-sobre-complexo.png" alt="" />
          </div>
        </div>
      </section>

      <modal-photo
        :photo="openPhotoSelected"
        v-if="showModalPhotos"
        @close="showModalPhotos = false"
      />
    </template>
  </layout-stage>
</template>

<script>
import LayoutStage from "@/components/LayoutStage";
import IconBack from "@/components/icons/IconBack";
import IconCompass from "@/components/icons/IconCompass";
// import IconSquare from "@/components/icons/IconSquare";
import IconStore from "@/components/icons/IconStore";
import ModalPhoto from "@/components/ModalPhoto";

export default {
  name: "About360",

  components: {
    LayoutStage,
    IconBack,
    IconCompass,
    // IconSquare,
    IconStore,
    ModalPhoto,
  },

  data() {
    return {
      showModalPhotos: false,
      openPhotoSelected: "",
      mockImplantation: [
        {
          image: require("@/assets/images/icon-implantation-01.svg"),
          imageMap: require("@/assets/images/icon-map-implantation-01.svg"),
          name: "Faculdade UNI-BH",
          // imageModal: require("@/assets/images/image-implantation-07.png"),
        },
        {
          image: require("@/assets/images/icon-implantation-02.svg"),
          imageMap: require("@/assets/images/icon-map-implantation-02.svg"),
          name: "Faculdade Newton Paiva",
        },
        {
          image: require("@/assets/images/icon-implantation-03.svg"),
          imageMap: require("@/assets/images/icon-map-implantation-03.svg"),
          name: "Colégio Unimaster",
        },
        {
          image: require("@/assets/images/icon-implantation-04.svg"),
          imageMap: require("@/assets/images/icon-map-implantation-04.svg"),
          name: "Supermecado Supernosso",
        },
        {
          image: require("@/assets/images/icon-implantation-05.svg"),
          imageMap: require("@/assets/images/icon-map-implantation-05.svg"),
          name: "Supermecado BH",
        },
        {
          image: require("@/assets/images/icon-implantation-06.svg"),
          imageMap: require("@/assets/images/icon-map-implantation-06.svg"),
          name: "Supermecado Verdemar",
        },
        {
          image: require("@/assets/images/icon-implantation-07.svg"),
          imageMap: require("@/assets/images/icon-map-implantation-07.svg"),
          name: "Supermecado Dia",
        },
        {
          image: require("@/assets/images/icon-implantation-08.svg"),
          imageMap: require("@/assets/images/icon-map-implantation-08.svg"),
          name: "Drogaria Araújo",
        },
        {
          image: require("@/assets/images/icon-implantation-09.svg"),
          imageMap: require("@/assets/images/icon-map-implantation-09.svg"),
          name: "Drogaria Pague Menos",
        },
        {
          image: require("@/assets/images/icon-implantation-10.svg"),
          imageMap: require("@/assets/images/icon-map-implantation-10.svg"),
          name: "EABH",
        },
        {
          image: require("@/assets/images/icon-implantation-11.svg"),
          imageMap: require("@/assets/images/icon-map-implantation-11.svg"),
          name: "Academia Contorno do Corpo",
        },
        {
          image: require("@/assets/images/icon-implantation-12.svg"),
          imageMap: require("@/assets/images/icon-map-implantation-12.svg"),
          name: "La Bomboneira",
        },
        {
          image: require("@/assets/images/icon-implantation-13.svg"),
          imageMap: require("@/assets/images/icon-map-implantation-13.svg"),
          name: "Caixa Econômica Federal",
        },
        {
          image: require("@/assets/images/icon-implantation-14.svg"),
          imageMap: require("@/assets/images/icon-map-implantation-14.svg"),
          name: "Banco Santander",
        },
        {
          image: require("@/assets/images/icon-implantation-14.svg"),
          imageMap: require("@/assets/images/icon-map-implantation-14.svg"),
          name: "Banco Itaú",
        },
        {
          image: require("@/assets/images/icon-implantation-14.svg"),
          imageMap: require("@/assets/images/icon-map-implantation-14.svg"),
          name: "BB - Banco do Brasil",
          imageModal: require("@/assets/images/image-implantation-14.png"),
        },
        {
          image: require("@/assets/images/icon-implantation-14.svg"),
          imageMap: require("@/assets/images/icon-map-implantation-14.svg"),
          name: "Shopping Trigopane",
        },
      ],
    };
  },

  methods: {
    openPhoto(photoUrl) {
      this.openPhotoSelected = photoUrl;
      this.showModalPhotos = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.block-menu {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.block-content {
  margin-top: 10px;
  max-width: 1301px;
  width: 100%;
  position: relative;
  background: var(--amulet);
  border-radius: 30px 0 70px 15px;
  padding: 0 0px 0 0;
  margin-right: 10px;
  max-height: 835px;
  overflow: hidden;
  margin-right: 20px;

  .block-content-center {
    max-width: 1282px;
    height: 835px;
    background: var(--sauvignon);
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 30px 0 77px 15px;
    padding: 84px 34px;

    .col-list {
      max-width: 280px;
      width: 100%;
      height: 700px;
      overflow-y: auto;
      margin-right: 10px;

      ul {
        li {
          margin-bottom: 12px;
          span {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-size: 20px;
            line-height: 28px;
            color: var(--pineGreen);

            &.clickable {
              cursor: pointer;
            }

            &.col-list_counter {
              width: 30px;
              height: 30px;
              background: var(--pineGreen);
              color: var(--white);
              border-radius: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-right: 8px;
            }
          }
        }
      }

      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-track {
        border-radius: 100px;
        background: rgba(0, 0, 0, 0.1);
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 100px;
        background: var(--pineGreen);
      }
    }
  }

  //Responsive mode
  @media (max-width: 1680px) {
  }

  @media (max-width: 1440px) {
    margin-top: 50px;
    max-width: 895px;
    max-height: 584px;

    .block-content-center {
      height: 100%;
      max-width: 880px;

      .col-list {
        max-width: 240px;
        height: 400px;

        ul {
          li {
            margin-bottom: 8px;
            span {
              font-size: 16px;
              line-height: 24px;
              &.col-list_counter {
                width: 20px;
                height: 20px;
                font-size: 12px;
                margin-right: 8px;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 1366px) {
  }

  @media (max-width: 1200px) {
  }

  @media (max-width: 1024px) {
  }

  @media only screen and (max-width: 926px) and (orientation: landscape) {
    max-width: 500px;
    position: fixed;
    right: 0;
    bottom: 10px;
  }

  @media only screen and (max-width: 780px) and (orientation: landscape) {
    max-width: 550px;
  }

  @media (max-width: 568px) {
  }
}

.back-button {
  color: var(--amulet);
  font-weight: var(--weight-regular);

  ::v-deep.icon-menu {
    stroke: var(--amulet);
  }
}

.block-menu_secondary {
  a {
    color: var(--amulet);

    ::v-deep.icon-menu {
      stroke: var(--amulet);
    }

    &:hover,
    &:active,
    &:focus,
    &.router-link-active {
      color: var(--pineGreen);
      background: var(--amulet);

      ::v-deep.icon-menu {
        stroke: var(--pineGreen);
      }
    }
  }

  @media (max-width: 1200px) {
    li:nth-child(2) {
      display: none;
    }
  }
}
</style>
